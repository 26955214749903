export class RxTypeMaster {
  id: number;
  rxTypeID?: string;
  rxTypeName?: string;
  vaccine_Y_N?: string;
  sortOrder?: number;
  active?: boolean;
}
export class RxSourceMaster {
  id: number;
  sourceCodeID?: string;
  sourceCodeName?: string;
  sortOrder?: number;
  active?: boolean;
}
export class UnitMaster {
  unitID: number;
  unitname?: string;
  createdDate?: Date;
  sortOrder?: number;
  active?: boolean;
}
export class ClientLocations {
  id: number;
  locationID?: string;
  locationName?: string;
  sortOrder?: number;
  active?: boolean;
}
export class Printer {
  id: number;
  printerName?: string;
  active?: boolean;
}
export class AdminSettings {
  id: number;
  keyName?: string;
  keyValue?: string;
  notes?: string;
  active?: boolean;
  category?: string;
  inputType?: string;
  isdisplayonpage?: boolean;
}
export class RxSource {
  id: number;
  sourceCodeID?: string;
  sourceCodeName?: string;
  createdby?: string;
  createdon?: Date;
  editedby?: string;
  editedon?: Date;
  deleteflag?: number;
  sortOrder?: number;
  active?: boolean;
}
export class Label {
  id: number;
  labelID?: string;
  labelXml?: string;
  active?: boolean;
}
export class VendorMaster {
  vendorid: number;
  vendorName?: string;
  address?: string;
  email?: string;
  phone?: string;
  contactPerson?: string;
  isActive?: boolean;
  showasteriskmark: boolean;
  vendorID: number;
  orderingProductID?: number;
  price?: number;
}
export class OrderingProductMaster {
  orderingProductID: number;
  orderingProductNo?: string;
  orderingProductNoWithName: string;
  orderingProductDescription?: string;
  itemuom?: number;
  purchaseuom?: number;
  purconversionfactor?: number;
  active?: boolean;
  itemUOMString?: string;
  purchaseUOMString?: string;
  
}

export class OrdProdNoAndLotNo {
  orderingProductID: number;
  lotNo: string;
}
export class ExpDateList {
  lotNo: string;
  expiryDate: string;
}
export class OrderingProductVendorPricing {
  ovpid: number;
  orderingProductID?: number;
  vendorID?: number;
  price?: number;
  selected?: boolean;
  active?: boolean;
  vendorName?: string;
}

export class OrderstatusMaster {
  statusID: number;
  statusName: string;
  sortOrder?: number;
  active?: boolean;
}
export class DropDownModel {
  value: number;
  text?: string;
}

export class EmailTemplate {
  id: number;
  templateName: string;
  subject: string;
  templateBody: string;
}
export class updateAdminSettings {
  id: number;
  value: string;
}
