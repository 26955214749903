import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
//import { PhoneMaskDirective } from '../phone-mask.directive';
import { EmailTemplateComponent } from './email-template/email-template.component';
import { LabelComponent } from './label/label.component';
import { LocationComponent } from './location/location.component';
import { MastersRoutingModule } from './masters-routing.module';
import { OrderingProductMasterComponent } from './ordering-product-master/ordering-product-master.component';
import { PrinterComponent } from './printer/printer.component';
import { RxTypeComponent } from './rx-type/rx-type.component';
import { RxsourceComponent } from './rxsource/rxsource.component';
import { SettingsComponent } from './settings/settings.component';
import { UnitComponent } from './unit/unit.component';
import { VendorMasterComponent } from './vendor-master/vendor-master.component';
import { VfcComponent } from './vfc/vfc.component';



@NgModule({
  declarations: [RxTypeComponent, UnitComponent, LocationComponent, PrinterComponent, SettingsComponent, VfcComponent, LabelComponent, VendorMasterComponent,
    OrderingProductMasterComponent, EmailTemplateComponent, RxsourceComponent],// PhoneMaskDirective],
  exports: [
    //PhoneMaskDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule,
    MastersRoutingModule
  ]
})
export class MastersModule { }
