import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}

export class WhiteSpaceValidator {
  static cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    if (control.value != null) {
      if ((control.value as string).trim().length <= 0) {
        return { cannotContainSpace: true }
      }
    }

    return null;
  }
}

export class CheckDashes {
  static IsContainTwoDashes(control: AbstractControl): ValidationErrors | null {
    if (control.value != null) {

      let str = (control.value as string).trim();
      let ch = '-';
      let count = str.split(ch).length - 1;
      /*if (((control.value as string).trim().match("/-/g")|| []).length != 2) */
      if (count != 2) {
        return { IsContainTwoDashes: true }
      }
    }

    return null;
  }
}

export class IsValueZero {
  static isValueZero(control: AbstractControl): ValidationErrors | null {
    if (control.value != null) {
      if (control.value == "0") {
        return { isValueZero: true }
      }
    }

    return null;
  }
}
