import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientLocations } from '../../_models';
import { CommonService } from '../../_services/common.service';
import { MastersService } from '../../_services/masters.service';
import { UtilityServices } from '../../_helpers';
import { RxMasterServices } from '../../_services/_inventory/rxmaster.service';
import { WhiteSpaceValidator } from '../../_helpers/_validations/must-match';

declare function ShowMessage(config): any;
declare function InitBasicDataTable(selector, title): any;
declare var $: any;
declare function AddRemoveClass(selector): any;

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {
  private locationList: ClientLocations[];
  private location: ClientLocations;
  IsEdit: boolean = false;
  IsErrorOccurredLocationId = false;
  IsErrorOccurredLocationName = false;
  changeId: number;
  rowClicked: any;
  locationForm: FormGroup;
  addlocationForm: FormGroup;
  showDiv: boolean = false;
  allbarcode: string[];
  rtnval: number;

  constructor(private formBuilder: FormBuilder, private masterService: MastersService, private commonService: CommonService, private utilityServices: UtilityServices, private Rxmasterservices: RxMasterServices) {
    this.GetAllLocations();
  }
  ngOnInit() {
    this.locationForm = this.formBuilder.group({
      ID: [null],
      LocationID: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      LocationName: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      SortOrder: [null, (Validators.required, Validators.pattern("^[0-9]*$"))],
      Active: [null, Validators.required]
    });
    this.disableEdit();
    this.addlocationForm = this.formBuilder.group({
      LocationID: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      LocationName: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      SortOrder: [1, (Validators.required, Validators.pattern("^[0-9]*$"))],
      Active: [1, Validators.required]
    });
  }
  InitDataTable() {
    InitBasicDataTable("#SRX_LocationMaster", "SRX_LocationMaster");
  }
  AddRemoveClass() {
    AddRemoveClass("#addnewtab");
  }
  get f() {
    return this.locationForm.controls;
  }
  get a() {
    return this.addlocationForm.controls;
  }
  GetAllLocations() {

    this.masterService.GetAllLocations().subscribe((res: any) => {
      $('#SRX_LocationMaster').dataTable().fnClearTable();
      $('#SRX_LocationMaster').dataTable().fnDestroy();

      this.locationList = [];
      this.locationList = res.data;
      if (this.locationList.length > 0) {
        if (this.changeId >= 1 && this.locationList.find(o => o.id == this.changeId) != null) {
          this.location = this.locationList.find(o => o.id == this.changeId);
          this.changeTableRowColor(this.locationList.findIndex(o => o.id == this.changeId));
          //$("#SRX_LocationMaster").DataTable().row(this.locationList.findIndex(o => o.id == this.changeId)).scrollTo();
        }
        else {
          this.location = this.locationList[0];
          this.changeTableRowColor(0);
        }
        this.showDiv = true;
        this.changeId = this.location.id;
      }
      else {
        this.showDiv = false;
      }
    });
  }
  changeTableRowColor(index: any) {
    this.rowClicked = index;
  }
  GetDetails(locations) {
    this.location = locations;
    this.changeId = this.location.id;
    this.disableEdit();
  }
  enableEdit() {
    this.locationForm.enable();
    this.IsEdit = true;
    if (this.location != null) {
      this.changeId = this.location.id;
    }
  }
  disableEdit() {
    this.locationForm.disable();
    this.IsEdit = false;
    if (this.changeId != null && this.locationList != null) {
      this.GetAllLocations();
    }
  }
  ResetAddForm() {
    this.addlocationForm.reset();
    this.addlocationForm.controls['Active'].patchValue(1);
    this.addlocationForm.controls['SortOrder'].patchValue(1);
  }
   edit() {
    this.utilityServices.startLoader();
    //await this.ValidateInput(this.f.ID.value, 'LocationID', this.f.LocationID.value, 'LocationID', 1);
    //await this.ValidateInput(this.f.ID.value, 'LocationName', this.f.LocationName.value, 'LocationName', 1);
    //if (this.IsErrorOccurredLocationId || this.IsErrorOccurredLocationName) {
    //  this.utilityServices.stopLoader();
    //  return;
    //}
    if (this.locationForm.invalid) {
      this.locationForm.markAllAsTouched();
      this.utilityServices.stopLoader();
      //ShowMessage({ type: "error", message: "Please fill form properly" });
      return;
    }
    this.commonService.ValidateInput("ClientLocations", "ID", this.f.ID.value, 'LocationID', this.f.LocationID.value).subscribe((res: any) => {
      if (res.isSuccess) {
        if (res.data == true) {
          this.IsErrorOccurredLocationId = true;
          ShowMessage({ type: "error", message: "Location Code '" + this.f.LocationID.value + "' already exists." });
          this.utilityServices.stopLoader();

        }
        else {
          this.IsErrorOccurredLocationId = false;
          this.commonService.ValidateInput("ClientLocations", "ID", this.f.ID.value, 'LocationID', this.f.LocationID.value).subscribe((res: any) => {
            if (res.isSuccess) {
              if (res.data == true) {
                this.IsErrorOccurredLocationName = true;
                ShowMessage({ type: "error", message: "Location Name '" + this.f.LocationName.value + "' already exists." });
                this.utilityServices.stopLoader();
              }
              else {

                this.IsErrorOccurredLocationName = false;
                if (this.f.Active.value == 0) {
                  this.utilityServices.stopLoader();
                   this.GetInventoryNetQtyValue(this.location.id.toString());
                  if (this.rtnval != 0) {
                    ShowMessage({ type: "error", message: "Location cannot be made inactive till it has available items." });
                    this.disableEdit();
                    this.utilityServices.stopLoader();
                    return
                  }
                  else {
                    this.saveEditedDetails();
                  }
                }
                else {
                  this.saveEditedDetails();
                }
                this.utilityServices.stopLoader();
              }
            }
          }, (err) => {
            ShowMessage({ type: "error", message: "Something went wrong..." });
          });
        }
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });

    //if (this.f.Active.value == 0) {
    //  this.utilityServices.stopLoader();
    //  await this.GetInventoryNetQtyValue(this.location.id.toString());
    //  if (this.rtnval != 0) {
    //    ShowMessage({ type: "error", message: "Location cannot be made inactive till it has available items." });
    //    this.disableEdit();
    //    return
    //  }
    //  else {
    //    this.saveEditedDetails();
    //  }
    //}
    //else {
    //  this.saveEditedDetails();
    //}
  }
  saveEditedDetails() {
    const mo: ClientLocations = new ClientLocations();
    mo.id = this.f.ID.value;
    mo.locationID = this.f.LocationID.value;
    mo.locationName = this.f.LocationName.value;
    mo.sortOrder = this.f.SortOrder.value;
    mo.active = this.f.Active.value;

    this.changeId = 0;
    this.masterService.SaveLocationData(mo).subscribe((res: any) => {
      if (res.isSuccess) {
        this.changeId = res.data;
        this.IsEdit = false;
        this.addlocationForm.reset();
        this.addlocationForm.controls['Active'].setValue(1);
        this.GetAllLocations();
        this.disableEdit();
        this.utilityServices.stopLoader();
        ShowMessage({ type: "success", message: res.message });
      }
      else {
        this.utilityServices.stopLoader();
        ShowMessage({
          type: "error", message: res.message
        });
      }
    }, (err) => {
      this.utilityServices.stopLoader();
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
  }
  add() {
    this.utilityServices.startLoader();
    if (this.addlocationForm.invalid) {
      this.addlocationForm.markAllAsTouched();
      //ShowMessage({ type: "error", message: "Please fill form properly" });
      this.utilityServices.stopLoader();
      return;
    }    
    //await this.ValidateInput('0', 'LocationID', this.a.LocationID.value, 'LocationID', 2);
    //await this.ValidateInput('0', 'LocationName', this.a.LocationName.value, 'LocationName', 2);
    //if (this.IsErrorOccurredLocationId || this.IsErrorOccurredLocationName) {
    //  this.utilityServices.stopLoader();
    //  return;
    //}

   this.commonService.ValidateInput("ClientLocations", "ID", "0", 'LocationID', this.a.LocationID.value).subscribe((res: any) => {
     if (res.isSuccess) {
       if (res.data == true) {
           this.IsErrorOccurredLocationId = true;
         ShowMessage({ type: "error", message: "Location Code '" + this.a.LocationID.value + "' already exists." });
         this.utilityServices.stopLoader();
         
       }
       else {
           this.IsErrorOccurredLocationId = false;
         this.commonService.ValidateInput("ClientLocations", "ID", "0", 'LocationName', this.a.LocationName.value).subscribe((res: any) => {
           if (res.isSuccess) {
             if (res.data == true) {
                 this.IsErrorOccurredLocationName = true;
               ShowMessage({ type: "error", message: "Location Name '" + this.a.LocationName.value + "' already exists." });
               this.utilityServices.stopLoader();
             }
             else {
              
               this.IsErrorOccurredLocationName = false;
               const mo: ClientLocations = new ClientLocations();
               mo.id = 0;
               mo.locationID = this.a.LocationID.value;
               mo.locationName = this.a.LocationName.value;
               mo.sortOrder = this.a.SortOrder.value;
               mo.active = this.a.Active.value;


               this.changeId = 0;
               this.masterService.SaveLocationData(mo).subscribe((res: any) => {
                 if (res.isSuccess) {
                   this.changeId = res.data;
                   this.addlocationForm.reset();
                   this.addlocationForm.controls['Active'].setValue(1);
                   this.GetAllLocations();
                   this.disableEdit();
                   $("#default-example-modal-lg-center").modal('hide');
                   this.utilityServices.stopLoader();
                   ShowMessage({ type: "success", message: res.message });
                 }
                 else {
                   this.utilityServices.stopLoader();
                   ShowMessage({
                     type: "error", message: res.message
                   });
                 }
               }, (err) => {
                 this.utilityServices.stopLoader();
                 ShowMessage({ type: "error", message: "Something went wrong..." });
               });
               this.utilityServices.stopLoader();
             }
           }
         }, (err) => {
           ShowMessage({ type: "error", message: "Something went wrong..." });
         });
       }
     }
   }, (err) => {
     ShowMessage({ type: "error", message: "Something went wrong..." });
   });

    //const mo: ClientLocations = new ClientLocations();
    //mo.id = 0;
    //mo.locationID = this.a.LocationID.value;
    //mo.locationName = this.a.LocationName.value;
    //mo.sortOrder = this.a.SortOrder.value;
    //mo.active = this.a.Active.value;


    //this.changeId = 0;
    //this.masterService.SaveLocationData(mo).subscribe((res: any) => {
    //  if (res.isSuccess) {
    //    this.changeId = res.data;
    //    this.addlocationForm.reset();
    //    this.addlocationForm.controls['Active'].setValue(1);
    //    this.GetAllLocations();
    //    this.disableEdit();
    //    $("#default-example-modal-lg-center").modal('hide');
    //    this.utilityServices.stopLoader();
    //    ShowMessage({ type: "success", message: res.message });
    //  }
    //  else {
    //    this.utilityServices.stopLoader();
    //    ShowMessage({
    //      type: "error", message: res.message
    //    });
    //  }
    //}, (err) => {
    //  this.utilityServices.stopLoader();
    //  ShowMessage({ type: "error", message: "Something went wrong..." });
    //});

  }
  onlyNumbers(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 47 && k < 59));
  }
  Remove(ID: number) {
    this.utilityServices.startLoader();
    this.masterService.RemoveLocation(ID.toString()).subscribe((res: any) => {
      if (res.isSuccess) {
        $("#SRX_LocationMaster").DataTable().row(this.locationList.findIndex(o => o.id == ID)).remove();
        this.GetAllLocations();
        this.utilityServices.stopLoader();
        ShowMessage({ type: "success", message: res.message });
      }
      else {
        this.utilityServices.stopLoader();
        ShowMessage({
          type: "error", message: res.message
        });
      }
    }, (err) => {
      this.utilityServices.stopLoader();
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
  }
  async ConfirmDelete(ID: number) {
    await this.GetInventoryNetQtyValue(this.location.id.toString());
    if (this.rtnval != 0) {
      ShowMessage({ type: "error", message: "Location cannot be deleted till it has available items." });
      return
    }

    var x = confirm("Are you sure you want to remove?");
    if (x)
      this.Remove(ID);
    else
      return false;
  }

  async GetInventoryNetQtyValue(location: string): Promise<any> {
    await this.Rxmasterservices.GetInventoryNetQtyValue(location)
      .toPromise()
      .then((response: Response) => {
        this.rtnval = response["data"];
      });
  }
   ValidateInput(Id?: string, FieldName?: string, Value?: string, ControlName?: string, fn?: number) {
    this.commonService.ValidateInput("ClientLocations", "ID", Id, FieldName, Value).subscribe((res: any) => {
      if (res.isSuccess) {
        if (res.data == true) {
          if (ControlName == "LocationID") {
            this.IsErrorOccurredLocationId = true;
            ShowMessage({ type: "error", message: "Location Code '" + Value + "' already exists." });
          }
          if (ControlName == "LocationName") {
            this.IsErrorOccurredLocationName = true;
            ShowMessage({ type: "error", message: "Location Name '" + Value + "' already exists." });
          }

          //if (fn == 1 && ControlName == "LocationID") {
          //  this.locationForm.patchValue({
          //    LocationID: ''
          //  });
          //}
          //else if (fn == 2 && ControlName == "LocationID") {
          //  this.addlocationForm.patchValue({
          //    LocationID: ''
          //  });
          //}
          //if (fn == 1 && ControlName == "LocationName") {
          //  this.locationForm.patchValue({
          //    LocationName: ''
          //  });
          //}
          //else if (fn == 2 && ControlName == "LocationName") {
          //  this.addlocationForm.patchValue({
          //    LocationName: ''
          //  });
          //}
        }
        else {
          if (ControlName == "LocationID") {
            this.IsErrorOccurredLocationId = false;
          }
          if (ControlName == "LocationName") {
            this.IsErrorOccurredLocationName = false;
          }
        }
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
  }
}
