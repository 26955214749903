import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilityServices } from '../../_helpers';
import { WhiteSpaceValidator } from '../../_helpers/_validations/must-match';
import { AdminSettings } from '../../_models';
import { CommonService } from '../../_services/common.service';
import { MastersService } from '../../_services/masters.service';

declare function ShowMessage(config): any;
declare function InitBasicDataTable(selector, title): any;
declare var $: any;
declare function AddRemoveClass(selector): any;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  private settingsList: AdminSettings[];
  private setting: AdminSettings;
  IsEdit: boolean = false;
  changeId: number;
  rowClicked: any;
  settingForm: FormGroup;
  addSettingForm: FormGroup;
  showDiv: boolean = false;
  isReadOnly: boolean = true;
  IsErrorOccurredKeyName = false;
  constructor(private formBuilder: FormBuilder, private masterService: MastersService, private commonService: CommonService, private utilityServices: UtilityServices) {
    this.GetAllSettings();
  }

  ngOnInit() {
    this.settingForm = this.formBuilder.group({
      ID: [null],
      KeyName: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      KeyValue: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      Notes: [null],
      Active: [null, [Validators.required]],
    });
    this.disableEdit();
    this.addSettingForm = this.formBuilder.group({
      KeyName: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      KeyValue: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      Notes: [null],
      Active: [true, [Validators.required]],
    });
  }
  InitDataTable() {
    InitBasicDataTable("#SRX_AdminSettings", "SRX_AdminSettings");
  }
  AddRemoveClass() {

    AddRemoveClass("#addnewtab");
  }
  get f() {
    return this.settingForm.controls;
  }
  get a() {
    return this.addSettingForm.controls;
  }
  ResetAddForm() {
    this.addSettingForm.reset();
    this.addSettingForm.controls['Active'].patchValue(true);
  }
  GetAllSettings() {

    this.masterService.GetAllSettings().subscribe((res: any) => {
      $('#SRX_AdminSettings').dataTable().fnClearTable();
      $('#SRX_AdminSettings').dataTable().fnDestroy();
      this.settingsList = [];
      this.settingsList = res.data;
      
      if (this.settingsList.length > 0) {
        if (this.changeId >= 1 && this.settingsList.find(o => o.id == this.changeId) != null) {
          this.setting = this.settingsList.find(o => o.id == this.changeId);
          this.changeTableRowColor(this.settingsList.findIndex(o => o.id == this.changeId));
          //$("#SRX_AdminSettings").DataTable().row(this.settingsList.findIndex(o => o.id == this.changeId)).scrollTo();
        }
        else {
          this.setting = this.settingsList[0];
          this.changeTableRowColor(0);
        }
        this.showDiv = true;
        this.changeId = this.setting.id;
      }
      else {
        this.showDiv = false;
      }
    });
  }
  changeTableRowColor(index: any) {    
    this.rowClicked = index;
  }
  GetDetails(settings) {    
    this.setting = settings;
    this.changeId = this.setting.id;
    this.disableEdit();
  }
  enableEdit() {
    this.settingForm.enable();
    this.IsEdit = true;
    if (this.setting != null) {
      this.changeId = this.setting.id;
    }
  }
  disableEdit() {    
    this.settingForm.disable();
    this.IsEdit = false;
    if (this.changeId != null && this.settingsList != null) {
      this.GetAllSettings();
    }
  }
  edit() {
    if (this.settingForm.invalid) {
      this.settingForm.markAllAsTouched();
      //ShowMessage({ type: "error", message: "Please fill form properly" });
      return;
    }
    //this.ValidateInput(this.f.ID.value, 'KeyName', this.f.KeyName.value, 'KeyName');
    //if (this.IsErrorOccurredKeyName) {
    //  this.utilityServices.stopLoader();
    //  return;
    //}

    this.commonService.ValidateInput("AdminSettings", "ID", this.f.ID.value, "KeyName", this.f.KeyName.value).subscribe((res: any) => {
      if (res.isSuccess) {
        if (res.data == true) {
            this.IsErrorOccurredKeyName = true;
          ShowMessage({ type: "error", message: "Setting name '" + this.f.KeyName.value + "' already exists." });
          this.utilityServices.stopLoader();

          //if (fn == 1 && ControlName == "KeyName") {
          //  this.settingForm.patchValue({
          //    KeyName: ''
          //  });
          //}
          //else if (fn == 2 && ControlName == "KeyName") {
          //  this.addSettingForm.patchValue({
          //    KeyName: ''
          //  });
          //}
        }
        else {
          this.IsErrorOccurredKeyName = false;
          const mo: AdminSettings = new AdminSettings();
          mo.id = this.f.ID.value;
          mo.keyName = this.f.KeyName.value;
          mo.keyValue = this.f.KeyValue.value;
          mo.notes = this.f.Notes.value;
          mo.active = this.f.Active.value;

          this.changeId = 0;
          this.masterService.SaveSettingData(mo).subscribe((res: any) => {
            if (res.isSuccess) {
              this.changeId = res.data;
              this.IsEdit = false;
              this.addSettingForm.reset();
              this.addSettingForm.controls['Active'].setValue(true);
              this.GetAllSettings();
              this.disableEdit();
              ShowMessage({ type: "success", message: res.message });
            }
            else {
              ShowMessage({
                type: "error", message: res.message
              });
            }
          }, (err) => {
            ShowMessage({ type: "error", message: "Something went wrong..." });
          });
          this.utilityServices.stopLoader();
        }
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });

    //const mo: AdminSettings = new AdminSettings();
    //mo.id = this.f.ID.value;
    //mo.keyName = this.f.KeyName.value;
    //mo.keyValue = this.f.KeyValue.value;
    //mo.notes = this.f.Notes.value;
    //mo.active = this.f.Active.value;

    //this.changeId = 0;
    //this.masterService.SaveSettingData(mo).subscribe((res: any) => {
    //  if (res.isSuccess) {
    //    this.changeId = res.data;
    //    this.IsEdit = false;
    //    this.addSettingForm.reset();
    //    this.addSettingForm.controls['Active'].setValue(true);
    //    this.GetAllSettings();
    //    this.disableEdit();
    //    ShowMessage({ type: "success", message: res.message });
    //  }
    //  else {
    //    ShowMessage({
    //      type: "error", message: res.message
    //    });
    //  }
    //}, (err) => {
    //  ShowMessage({ type: "error", message: "Something went wrong..." });
    //});

  }



  add() {
    if (this.addSettingForm.invalid) {
      this.addSettingForm.markAllAsTouched();
      //ShowMessage({ type: "error", message: "Please fill form properly" });
      return;
    }
    //this.ValidateInput('0', 'KeyName', this.a.KeyName.value, 'KeyName');
    //if (this.IsErrorOccurredKeyName) {
    //  this.utilityServices.stopLoader();
    //  return;
    //}
    this.commonService.ValidateInput("AdminSettings", "ID", "0", "KeyName", this.a.KeyName.value).subscribe((res: any) => {
      if (res.isSuccess) {
        if (res.data == true) {
          this.IsErrorOccurredKeyName = true;
          ShowMessage({ type: "error", message: "Setting name '" + this.a.KeyName.value + "' already exists." });
          this.utilityServices.stopLoader();

          //if (fn == 1 && ControlName == "KeyName") {
          //  this.settingForm.patchValue({
          //    KeyName: ''
          //  });
          //}
          //else if (fn == 2 && ControlName == "KeyName") {
          //  this.addSettingForm.patchValue({
          //    KeyName: ''
          //  });
          //}
        }
        else {
          this.IsErrorOccurredKeyName = false;
          const mo: AdminSettings = new AdminSettings();
          mo.id = 0;
          mo.keyName = this.a.KeyName.value;
          mo.keyValue = this.a.KeyValue.value;
          mo.notes = this.a.Notes.value;
          mo.active = this.a.Active.value;


          this.changeId = 0;
          this.masterService.SaveSettingData(mo).subscribe((res: any) => {
            if (res.isSuccess) {
              this.changeId = res.data;
              this.addSettingForm.reset();
              this.addSettingForm.controls['Active'].setValue(true);
              this.GetAllSettings();
              this.disableEdit();
              ShowMessage({ type: "success", message: res.message });
            }
            else {
              ShowMessage({
                type: "error", message: res.message
              });
            }
            $("#default-example-modal-lg-center").modal('hide');
          }, (err) => {
            ShowMessage({ type: "error", message: "Something went wrong..." });
          });
          this.utilityServices.stopLoader();
        }
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
    //const mo: AdminSettings = new AdminSettings();
    //mo.id = 0;
    //mo.keyName = this.a.KeyName.value;
    //mo.keyValue = this.a.KeyValue.value;
    //mo.notes = this.a.Notes.value;
    //mo.active = this.a.Active.value;


    //this.changeId = 0;
    //this.masterService.SaveSettingData(mo).subscribe((res: any) => {
    //  if (res.isSuccess) {
    //    this.changeId = res.data;
    //    this.addSettingForm.reset();
    //    this.addSettingForm.controls['Active'].setValue(true);
    //    this.GetAllSettings();
    //    this.disableEdit();
    //    ShowMessage({ type: "success", message: res.message });
    //  }
    //  else {
    //    ShowMessage({
    //      type: "error", message: res.message
    //    });
    //  }
    //  $("#default-example-modal-lg-center").modal('hide');
    //}, (err) => {
    //  ShowMessage({ type: "error", message: "Something went wrong..." });
    //});

  }
  Remove(ID: number) {
    this.masterService.RemoveSettingData(ID).subscribe((res: any) => {
      if (res.isSuccess) {
        $("#SRX_AdminSettings").DataTable().row(this.settingsList.findIndex(o => o.id == ID)).remove();
        this.GetAllSettings();
        ShowMessage({ type: "success", message: res.message });
      }
      else {
        ShowMessage({
          type: "error", message: res.message
        });
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
  }
  ConfirmDelete(ID: number) {
    var x = confirm("Are you sure want to delete this record?");
    if (x)
      this.Remove(ID);
    else
      return false;
  }
  ValidateInput(Id?: string, FieldName?: string, Value?: string, ControlName?: string, fn?: number) {
    this.commonService.ValidateInput("AdminSettings", "ID", Id, FieldName, Value).subscribe((res: any) => {
      if (res.isSuccess) {
        if (res.data == true) {
          if (ControlName == "KeyName") {
            this.IsErrorOccurredKeyName = true;
            ShowMessage({ type: "error", message: "Setting name '" + Value + "' already exists." });
          }

          //if (fn == 1 && ControlName == "KeyName") {
          //  this.settingForm.patchValue({
          //    KeyName: ''
          //  });
          //}
          //else if (fn == 2 && ControlName == "KeyName") {
          //  this.addSettingForm.patchValue({
          //    KeyName: ''
          //  });
          //}
        }
        else {
          this.IsErrorOccurredKeyName = false;
        }
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
  }

}
