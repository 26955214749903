import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RxSource } from '../../_models';
import { CommonService } from '../../_services/common.service';
import { MastersService } from '../../_services/masters.service';
import { UtilityServices } from '../../_helpers';

declare function ShowMessage(config): any;
declare function InitBasicDataTable(selector, title): any;
declare var $: any;
declare function AddRemoveClass(selector): any;

@Component({
  selector: 'app-vfc',
  templateUrl: './vfc.component.html',
  styleUrls: ['./vfc.component.css']
})
export class VfcComponent implements OnInit {
  private vfcList: RxSource[];
  private vfc: RxSource;
  IsEdit: boolean = false;
  changeId: number;
  rowClicked: any;
  vfcForm: FormGroup;
  addVfcForm: FormGroup;
  showDiv: boolean = false;
  constructor(private formBuilder: FormBuilder, private masterService: MastersService, private commonService: CommonService, private utilityServices: UtilityServices) {
    this.GetAllVFCs();
  }

  ngOnInit() {
    this.vfcForm = this.formBuilder.group({
      ID: [null],
      SourceCodeID: [null, Validators.required],
      SourceCodeName: [null, Validators.required],
      SortOrder: [null],
      Active: [null, Validators.required]
    });
    this.disableEdit();
    this.addVfcForm = this.formBuilder.group({
      SourceCodeID: [null, Validators.required],
      SourceCodeName: [null, Validators.required],
      SortOrder: [null],
      Active: [1, Validators.required]
    });
  }
  InitDataTable() {
    InitBasicDataTable("#SRX_VFCMaster","SRX_VFCMaster");
  }
  AddRemoveClass() {
   
    AddRemoveClass("#addnewtab");
  }
  get f() {
    return this.vfcForm.controls;
  }
  get a() {
    return this.addVfcForm.controls;
  }
  onlyNumbers(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 47 && k < 59));
  }
  GetAllVFCs() {

    this.masterService.GetAllVFCs().subscribe((res: any) => {
      $('#SRX_VFCMaster').dataTable().fnClearTable();
      $('#SRX_VFCMaster').dataTable().fnDestroy();
      this.vfcList = [];
      this.vfcList = res.data;
      if (this.vfcList.length > 0) {
        if (this.changeId >= 1 && this.vfcList.find(o => o.id == this.changeId) != null) {
          this.vfc = this.vfcList.find(o => o.id == this.changeId);
          this.changeTableRowColor(this.vfcList.findIndex(o => o.id == this.changeId));
          //$("#SRX_VFCMaster").DataTable().row(this.vfcList.findIndex(o => o.id == this.changeId)).scrollTo();
        }
        else {
          this.vfc = this.vfcList[0];
          this.changeTableRowColor(0);
        }
        this.showDiv = true;
        this.changeId = this.vfc.id;
      }
      else {
        this.showDiv = false;
      }
    });
  }
  changeTableRowColor(index: any) {
    this.rowClicked = index;
  }
  GetDetails(vfcs) {
    this.vfc = vfcs;
    this.changeId = this.vfc.id;
    this.disableEdit();
  }
  enableEdit() {
    this.vfcForm.enable();
    this.IsEdit = true;
    if (this.vfc != null) {
      this.changeId = this.vfc.id;
    }
  }
  disableEdit() {
    this.vfcForm.disable();
    this.IsEdit = false;
    if (this.changeId != null && this.vfc != null) {
      this.GetAllVFCs();
    }

  }
  ResetAddForm() {
    this.addVfcForm.reset();
  }
  edit() {
    this.utilityServices.startLoader();
    if (this.vfcForm.invalid) {
      this.vfcForm.markAllAsTouched();
      this.utilityServices.stopLoader();
      //ShowMessage({ type: "error", message: "Please fill form properly" });
      return;
    }
    const mo: RxSource = new RxSource();
    mo.id = this.f.ID.value;
    mo.sourceCodeID = this.f.SourceCodeID.value;
    mo.sourceCodeName = this.f.SourceCodeName.value;
    mo.sortOrder = this.f.SortOrder.value;
    mo.active = this.f.Active.value;

    this.changeId = 0;
    this.masterService.SaveVFCData(mo).subscribe((res: any) => {
      if (res.isSuccess) {
        this.changeId = res.data;
        this.IsEdit = false;
        this.addVfcForm.reset();
        this.addVfcForm.controls['Active'].setValue(1);
        this.GetAllVFCs();
        this.disableEdit();
        this.utilityServices.stopLoader();
        ShowMessage({ type: "success", message: res.message });
      }
      else {
        this.utilityServices.stopLoader();
        ShowMessage({
          type: "error", message: res.message
        });
      }
    }, (err) => {
        this.utilityServices.stopLoader();
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });

  }
  add() {
    this.utilityServices.startLoader();
    if (this.addVfcForm.invalid) {
      this.addVfcForm.markAllAsTouched();
      this.utilityServices.stopLoader();
      //ShowMessage({ type: "error", message: "Please fill form properly" });
      return;
    }
    const mo: RxSource = new RxSource();
    mo.id = 0;
    mo.sourceCodeID = this.a.SourceCodeID.value;
    mo.sourceCodeName = this.a.SourceCodeName.value;
    mo.sortOrder = this.a.SortOrder.value;
    mo.active = this.a.Active.value;


    this.changeId = 0;
    this.masterService.SaveVFCData(mo).subscribe((res: any) => {
      if (res.isSuccess) {
        this.changeId = res.data;
        this.addVfcForm.reset();
        this.addVfcForm.controls['Active'].setValue(1);
        this.GetAllVFCs();
        this.disableEdit();
        this.utilityServices.stopLoader();
        ShowMessage({ type: "success", message: res.message });
      }
      else {
        this.utilityServices.stopLoader();
        ShowMessage({
          type: "error", message: res.message
        });
      }
      $("#default-example-modal-lg-center").modal('hide');
    }, (err) => {
        this.utilityServices.stopLoader();
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });

  }
  Remove(ID: number) {
    this.utilityServices.startLoader();
    this.masterService.RemoveVFC(ID.toString()).subscribe((res: any) => {
      if (res.isSuccess) {
        $("#SRX_VFCMaster").DataTable().row(this.vfcList.findIndex(o => o.id == ID)).remove();
        this.GetAllVFCs();
        this.utilityServices.stopLoader();
        ShowMessage({ type: "success", message: res.message });
      }
      else {
        this.utilityServices.stopLoader();
        ShowMessage({
          type: "error", message: res.message
        });
      }
    }, (err) => {
        this.utilityServices.stopLoader();
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
  }
  ConfirmDelete(ID: number) {
    var x = confirm("Are you sure want to delete this record?");
    if (x)
      this.Remove(ID);
    else
      return false;
  }
  ValidateInput(Id?: string, FieldName?: string, Value?: string, ControlName?: string, fn?: number) {
    this.commonService.ValidateInput("RxSource", "ID", Id, FieldName, Value).subscribe((res: any) => {
      if (res.isSuccess) {
        if (res.data == true) {
          ShowMessage({ type: "error", message: "Entered " + "Setting Name" + " is in use." });

          //if (fn == 1 && ControlName == "SourceCodeID") {
          //  this.vfcForm.patchValue({
          //    SourceCodeID: ''
          //  });
          //}
          //else if (fn == 2 && ControlName == "SourceCodeID") {
          //  this.addVfcForm.patchValue({
          //    SourceCodeID: ''
          //  });
          //}
        }
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
  }

}
