import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VendorMaster } from '../../_models';
import { CommonService } from '../../_services/common.service';
import { MastersService } from '../../_services/masters.service';
import { UtilityServices } from '../../_helpers';
import { WhiteSpaceValidator } from '../../_helpers/_validations/must-match';

declare function ShowMessage(config): any;
declare function InitBasicDataTable(selector, title): any;
declare var $: any;
declare function AddRemoveClass(selector): any;
declare function numberOnly(event): any;
declare function FormatPhoneNumber(event): any;

@Component({
  selector: 'app-vendor-master',
  templateUrl: './vendor-master.component.html',
  styleUrls: ['./vendor-master.component.css']
})
export class VendorMasterComponent implements OnInit {
  private vendorMasterList: VendorMaster[];
  private vendorMaster: VendorMaster;
  IsEdit: boolean = false;
  changeId: number = 0;
  rowClicked: any;
  vendorMasterForm: FormGroup;
  addVendorMasterForm: FormGroup;
  showDiv: boolean = false;
  IsErrorOccurredContactPerson = false;
  constructor(private formBuilder: FormBuilder, private masterService: MastersService, private commonService: CommonService, private utilityServices: UtilityServices) {
    this.GetVendorMasters();
  }
  ngOnInit() {
    this.vendorMasterForm = this.formBuilder.group({
      VendorID: [null],
      VendorName: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      Address: [null,],//[Validators.required, WhiteSpaceValidator.cannotContainSpace]
      Email: [null, [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      Phone: [null, [Validators.pattern("^(\\([0-9]{3}\\) | [0-9]{3}-)[0-9]{3}-[0-9]{4}$")]],//Validators.required, 
      ContactPerson: [null,],//[Validators.required, WhiteSpaceValidator.cannotContainSpace]
      IsActive: [null, Validators.required]
    });
    this.disableEdit();
    this.addVendorMasterForm = this.formBuilder.group({
      VendorName: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      Address: [null,],//[Validators.required, WhiteSpaceValidator.cannotContainSpace]
      Email: [null, [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      Phone: [null, [Validators.pattern("^(\\([0-9]{3}\\) | [0-9]{3}-)[0-9]{3}-[0-9]{4}$")]],//Validators.required,
      ContactPerson: [null,],//[Validators.required, WhiteSpaceValidator.cannotContainSpace]
      IsActive: [true, Validators.required]
    });
  }
  InitDataTable() {
    InitBasicDataTable("#SRX_VendorMaster", "SRX_VendorMaster");
  }
  AddRemoveClass() {

    AddRemoveClass("#addnewtab");
  }
  get f() {
    return this.vendorMasterForm.controls;
  }
  get a() {
    return this.addVendorMasterForm.controls;
  }
  ResetAddForm() {
    this.addVendorMasterForm.reset();
    this.addVendorMasterForm.controls['IsActive'].patchValue(true);
  }
  GetVendorMasters() {

    this.masterService.GetVendorMasters().subscribe((res: any) => {
      $('#SRX_VendorMaster').dataTable().fnClearTable();
      $('#SRX_VendorMaster').dataTable().fnDestroy();
      this.vendorMasterList = [];
      this.vendorMasterList = res.data;
      if (this.vendorMasterList.length > 0) {

        if (this.changeId >= 1 && this.vendorMasterList.find(o => o.vendorID == this.changeId) != null) {
          this.vendorMaster = this.vendorMasterList.find(o => o.vendorID == this.changeId);
          this.changeTableRowColor(this.vendorMasterList.findIndex(o => o.vendorID == this.changeId));
          // $("#SRX_PrinterMaster").DataTable().row(this.vendorMasterList.findIndex(o => o.vendorid == this.changeId)).scrollTo();
        }
        else {
          this.vendorMaster = this.vendorMasterList[0];
          this.changeTableRowColor(0);
        }
        this.showDiv = true;
        this.changeId = this.vendorMaster.vendorID;
      }
      else {
        this.showDiv = false;
      }
    });
  }

  changeTableRowColor(index: any) {
    this.rowClicked = index;
  }
  GetDetails(printers) {    
    this.vendorMaster = printers;
    this.changeId = this.vendorMaster.vendorID;
    this.disableEdit();
  }
  enableEdit() {

    this.vendorMasterForm.enable();
    this.IsEdit = true;
    if (this.vendorMaster != null) {
      this.changeId = this.vendorMaster.vendorID;
    }
  }
  disableEdit() {

    this.vendorMasterForm.disable();
    this.IsEdit = false;
    if (this.changeId != null && this.vendorMasterList != null) {
      this.GetVendorMasters();
    }
  }

  formatPhoneNumber(phoneNumberString: string): string {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }
  edit() {

    this.utilityServices.startLoader();
    if (this.vendorMasterForm.invalid) {
      this.vendorMasterForm.markAllAsTouched();
      this.utilityServices.stopLoader();
      //ShowMessage({ type: "error", message: "Please fill form properly" });
      return;
    }
    //this.ValidateContactPerson(this.f.VendorID.value, 'ContactPerson', this.f.ContactPerson.value, 'ContactPerson', 1);
    //if (this.IsErrorOccurredContactPerson) {
    //  this.utilityServices.stopLoader();
    //  return;
    //}
    this.commonService.ValidateInputVendorMaster("VendorMaster", "VendorID", this.f.VendorID.value, 'ContactPerson', this.f.ContactPerson.value, this.f.VendorName.value, this.f.Email.value).subscribe((res: any) => {
      if (res.isSuccess) {
        if (res.data == true) {
          this.IsErrorOccurredContactPerson = true;
          ShowMessage({ type: "error", message: "Contact person '" + this.f.ContactPerson.value + "' already exists." });
          this.utilityServices.stopLoader();
          //if (fn == 1 && ControlName == "Email") {
          //  this.vendorMasterForm.patchValue({
          //    Email: ''
          //  });
          //}
          //else if (fn == 2 && ControlName == "Email") {
          //  this.addVendorMasterForm.patchValue({
          //    Email: ''
          //  });
          //}
        }
        else {
          this.IsErrorOccurredContactPerson = false;

          const mo: VendorMaster = new VendorMaster();
          mo.vendorid = this.f.VendorID.value;
          mo.vendorName = this.f.VendorName.value;
          mo.address = this.f.Address.value;
          mo.email = this.f.Email.value;
          mo.phone = this.formatPhoneNumber(this.f.Phone.value);
          mo.contactPerson = this.f.ContactPerson.value;
          mo.isActive = this.f.IsActive.value;

          this.changeId = 0;
          this.masterService.SaveVendorMasterData(mo).subscribe((res: any) => {
            if (res.isSuccess) {
              this.changeId = res.data;
              this.IsEdit = false;
              this.addVendorMasterForm.reset();
              this.addVendorMasterForm.controls['IsActive'].setValue(true);
              this.GetVendorMasters();
              this.disableEdit();
              this.utilityServices.stopLoader();
              ShowMessage({ type: "success", message: res.message });
            }
            else {
              this.utilityServices.stopLoader();
              ShowMessage({
                type: "error", message: res.message
              });
            }
          }, (err) => {
            this.utilityServices.stopLoader();
            ShowMessage({ type: "error", message: "Something went wrong..." });
          });
          this.utilityServices.stopLoader();
        }
      }
    }, (err) => {
      ShowMessage(
        { type: "error", message: "Something went wrong..." });
    });
    //const mo: VendorMaster = new VendorMaster();
    //mo.vendorid = this.f.VendorID.value;
    //mo.vendorName = this.f.VendorName.value;
    //mo.address = this.f.Address.value;
    //mo.email = this.f.Email.value;
    //mo.phone = this.formatPhoneNumber(this.f.Phone.value);
    //mo.contactPerson = this.f.ContactPerson.value;
    //mo.isActive = this.f.IsActive.value;

    //this.changeId = 0;
    //this.masterService.SaveVendorMasterData(mo).subscribe((res: any) => {
    //  if (res.isSuccess) {
    //    this.changeId = res.data;
    //    this.IsEdit = false;
    //    this.addVendorMasterForm.reset();
    //    this.addVendorMasterForm.controls['IsActive'].setValue(true);
    //    this.GetVendorMasters();
    //    this.disableEdit();
    //    this.utilityServices.stopLoader();
    //    ShowMessage({ type: "success", message: res.message });
    //  }
    //  else {
    //    this.utilityServices.stopLoader();
    //    ShowMessage({
    //      type: "error", message: res.message
    //    });
    //  }
    //}, (err) => {
    //  this.utilityServices.stopLoader();
    //  ShowMessage({ type: "error", message: "Something went wrong..." });
    //});

  }
  add() {
    this.utilityServices.startLoader();
    if (this.addVendorMasterForm.invalid) {
      this.addVendorMasterForm.markAllAsTouched();
      this.utilityServices.stopLoader();
      //ShowMessage({ type: "error", message: "Please fill form properly" });
      return;
    }
    this.ValidateContactPerson('0', 'ContactPerson', this.a.ContactPerson.value, 'ContactPerson', 2);
    if (this.IsErrorOccurredContactPerson) {
      this.utilityServices.stopLoader();
      return;
    }
    const mo: VendorMaster = new VendorMaster();
    mo.vendorid = 0;
    mo.vendorName = this.a.VendorName.value;
    mo.address = this.a.Address.value;
    mo.email = this.a.Email.value;
    mo.phone = this.formatPhoneNumber(this.a.Phone.value);
    mo.contactPerson = this.a.ContactPerson.value;
    mo.isActive = this.a.IsActive.value;


    this.changeId = 0;
    this.masterService.SaveVendorMasterData(mo).subscribe((res: any) => {
      if (res.isSuccess) {
        this.changeId = res.data;
        this.addVendorMasterForm.reset();
        this.addVendorMasterForm.controls['IsActive'].setValue(true);
        this.GetVendorMasters();
        this.disableEdit();
        this.utilityServices.stopLoader();
        ShowMessage({ type: "success", message: res.message });
      }
      else {
        this.utilityServices.stopLoader();
        ShowMessage({
          type: "error", message: res.message
        });
      }
      $("#default-example-modal-lg-center").modal('hide');
    }, (err) => {
      this.utilityServices.stopLoader();
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });

  }
  Remove(ID: number) {

    this.utilityServices.startLoader();
    this.masterService.RemoveVendor(ID.toString()).subscribe((res: any) => {
      if (res.isSuccess) {
        $("#SRX_VendorMaster").DataTable().row(this.vendorMasterList.findIndex(o => o.vendorid == ID)).remove();
        this.GetVendorMasters();
        this.utilityServices.stopLoader();
        ShowMessage({ type: "success", message: res.message });
      }
      else {
        this.utilityServices.stopLoader();
        ShowMessage({
          type: "error", message: res.message
        });
      }
    }, (err) => {
      this.utilityServices.stopLoader();
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
  }
  ConfirmDelete(ID: number) {
    var x = confirm("Are you sure want to delete this record?");
    if (x)
      this.Remove(ID);
    else
      return false;
  }
  numberOnly(event): boolean {
    if (numberOnly(event) == true) {
      event.target.value = FormatPhoneNumber(event.target.value);
      return true;
    }
    else {
      return false;
    }
  }
  validatePhone(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 47 && k < 58) || k == 40 || k == 41 || k == 45 || k == 32);
  }
  ValidateContactPerson(Id?: string, FieldName?: string, Value?: string, ControlName?: string, fn?: number) {
    if (Value == null || Value.trim().length <= 0) {
      return;
    }

    if (fn == 1) {
      this.commonService.ValidateInputVendorMaster("VendorMaster", "VendorID", Id, FieldName, Value, this.f.VendorName.value, this.f.Email.value).subscribe((res: any) => {
        if (res.isSuccess) {
          if (res.data == true) {
            this.IsErrorOccurredContactPerson = true;
            ShowMessage({ type: "error", message: "Contact person '" + Value + "' already exists." });
            //if (fn == 1 && ControlName == "Email") {
            //  this.vendorMasterForm.patchValue({
            //    Email: ''
            //  });
            //}
            //else if (fn == 2 && ControlName == "Email") {
            //  this.addVendorMasterForm.patchValue({
            //    Email: ''
            //  });
            //}
          }
          else {
            this.IsErrorOccurredContactPerson = false;
          }
        }
      }, (err) => {
        ShowMessage(
          { type: "error", message: "Something went wrong..." });
      });
    }
    if (fn == 2) {
      this.commonService.ValidateInputVendorMaster("VendorMaster", "VendorID", Id, FieldName, Value, this.a.VendorName.value, this.a.Email.value).subscribe((res: any) => {
        if (res.isSuccess) {
          if (res.data == true) {
            this.IsErrorOccurredContactPerson = true;
            ShowMessage({ type: "error", message: "Contact person '" + Value + "' already exists." });
            //if (fn == 1 && ControlName == "Email") {
            //  this.vendorMasterForm.patchValue({
            //    Email: ''
            //  });
            //}
            //else if (fn == 2 && ControlName == "Email") {
            //  this.addVendorMasterForm.patchValue({
            //    Email: ''
            //  });
            //}
          }
          else {
            this.IsErrorOccurredContactPerson = false;
          }
        }
      }, (err) => {
        ShowMessage(
          { type: "error", message: "Something went wrong..." });
      });
    }
  }

  
}
