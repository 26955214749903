import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '..';
import { map, first } from 'rxjs/operators';
import { RxMaster } from '../../_models/_inventory/rxmaster';
import { InventoryTransactions } from '../../_models/_inventory/inventorytransactions';
import { InventoryReorder } from '../../_models/_inventory/inventoryreorder';
import { InventoryReorderQueued } from '../../_models/_inventory/inventoryreorderqueued';
import { recorditemusage } from '../../_models/_inventory/recorditemusage';

//import { environment } from '../../environments/environment';
//import { User, Profile } from '../_models';
//import { ChangePassword } from '../_models/changepassword';
//import { AuthenticationService } from '.';
//import { map } from 'rxjs/operators';
//import { UserImports } from '../_models/userimports';


@Injectable({ providedIn: 'root' })
export class RxMasterServices {
  private userAccessToken: string;
  constructor(private http: HttpClient,
    private authenticationService: AuthenticationService) {
    if (this.authenticationService.currentUserValue) {
      this.userAccessToken = this.authenticationService.userAccessToken;
    }
  }

  GetAll() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    return this.http.get<any>(`${environment.apiUrl}/rx-master/get-all`, options).pipe(map(response => response));
  }

  getById(id: number) {
    return this.http.get<RxMaster>(`${environment.apiUrl}/users/${id}`);
  }

  GetInventoryUOM() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams();

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/rx-master/get-inventoryuom`, options).pipe(map(response => response));
  }
  GetRxType() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams();

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/rx-master/get-rxtype`, options).pipe(map(response => response));
  }

  GetRxSource() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams();

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/rx-master/get-rxsource`, options).pipe(map(response => response));
  }

  GetUseCodeType() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams();

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/rx-master/get-usecodetype`, options).pipe(map(response => response));
  }
  GetInventoryDetails(srxid: string) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams().set('srxid', srxid);

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/rx-master/get-inventorydetails`, options).pipe(map(response => response));
  }

  GetInventoryDetails_onlyQtyValue(srxid: string, loc: string) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams().set('srxid', srxid).set('location', loc);


    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/rx-master/get-inventorydetails-onlyqtyvalue`, options).pipe(map(response => response));
  }

  GetInventoryNetQtyValue(loc: string) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams().set('location', loc);


    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/rx-master/get-inventory-netqtyvalue`, options).pipe(map(response => response));
  }

  GetInventoryReorderDetails(orderingproductNo: any) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams().set('orderingproductNo', orderingproductNo);

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/rx-master/get-inventoryreorderdetails`, options).pipe(map(response => response));
  }

  GetInventoryValueReportDetails(srxid: any) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams().set('srxid', srxid);

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/rx-master/get-inventoryvaluereportdetails`, options).pipe(map(response => response));
  }
  GetUnitNameById(unitid: any) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const parameters = new HttpParams().set('unitid', unitid);

    const options = {
      headers: headers,
      params: parameters
    };
    return this.http.get<any>(`${environment.apiUrl}/rx-master/get-unitnamebyid`, options).pipe(map(response => response));
  }
  GetDecodedBarcode(srxid: string, barcode: string, listname: string, listsource: string, isMedicine: boolean) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams().set("barcode", barcode)
      .set("listname", listname)
      .set("listsource", listsource)
      .set("srxid", srxid)
      .set("isMedicine", isMedicine.toString())
      //.set("usebarcode", usebarcode)
      ;

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/rx-master/get-decodebarcode`, options).pipe(map(response => response));
  }

  GetRxDetailsfromBarcode(barcode: string) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams().set("barcode", barcode);

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/rx-master/get-rxdetailsfrombarcode`, options).pipe(map(response => response));
  }
  GetRxDetailsfromNDC(barcode: string) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams().set("barcode", barcode);

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/rx-master/get-rxdetailsfromndc`, options).pipe(map(response => response));
  }
  GetAnalysedOnDate() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    return this.http.get<any>(`${environment.apiUrl}/rx-master/get-analysedondate`, options).pipe(map(response => response));
  }
  SaveInventoryTransactions(inventorytransaction: InventoryTransactions) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    //inventorytransaction.rxmodel[0] = rxmodel;

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/rx-master/saveinventorytransaction`, inventorytransaction, options).pipe(map(response => response));
  }

  SaveRxMasterData(rxmaster: RxMaster) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/rx-master/saverxmasterdata`, rxmaster, options).pipe(map(response => response));
  }

  DeleteRxMasterdata(rxmaster: RxMaster, allowhistorydel: any) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const parameters = new HttpParams()
      .set("allowhistorydel", allowhistorydel)
      ;
    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.post<any>(`${environment.apiUrl}/rx-master/deleterxmasterdata`, rxmaster, options).pipe(map(response => response));
  }


  SaveInventoryReorderDetails(inventoryreorder: InventoryReorder) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/rx-master/saveinventoryreorderdetails`, inventoryreorder, options).pipe(map(response => response));
  }

  SaveInventoryReorderQueuedData() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    //const parameters = new HttpParams().set("barcode", barcode);
    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/rx-master/saveinventoryreorderqueueddata`, options).pipe(map(response => response));
  }

  SavePurchaseOrder(inventoryqueuedtls: InventoryReorderQueued[]) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/rx-master/savepurchaseorder`, inventoryqueuedtls, options).pipe(map(response => response));
  }
  EditPurchaseOrder(inventoryqueuedtls: InventoryReorderQueued[]) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/rx-master/editpurchaseorder`, inventoryqueuedtls, options).pipe(map(response => response));
  }

  savePurchaseOrderNew(inventoryqueuedtls: InventoryReorderQueued[], flag: any) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const parameters = new HttpParams()
      .set("addeditflag", flag);
    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.post<any>(`${environment.apiUrl}/rx-master/SavePurchaseOrderNew`, inventoryqueuedtls, options).pipe(map(response => response));
  }

  EditOrderStatus(inventoryqueuedtls: InventoryReorderQueued[], statusid: any) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const parameters = new HttpParams()
      .set("statusid", statusid)
      ;
    //set("hdrid", hdrid)
    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.post<any>(`${environment.apiUrl}/rx-master/editorderstatus`, inventoryqueuedtls, options).pipe(map(response => response));
  }

  GetpurchaseOrderListByVendorId(vendorid: any) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const parameters = new HttpParams().set('vendorid', vendorid);
    const options = {
      headers: headers,
      params: parameters
    };
    return this.http.get<any>(`${environment.apiUrl}/rx-master/get-purchaseorderbyvendorid`, options).pipe(map(response => response));
  }
  GetPODetailsbyPONo(ponumber: any) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const parameters = new HttpParams().set('ponumber', ponumber);
    const options = {
      headers: headers,
      params: parameters
    };
    return this.http.get<any>(`${environment.apiUrl}/rx-master/get-podetaisbyponumber`, options).pipe(map(response => response));
  }
  GetInventoryTransactionbyPONo(ponumber: any) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const parameters = new HttpParams().set('ponumber', ponumber);
    const options = {
      headers: headers,
      params: parameters
    };
    return this.http.get<any>(`${environment.apiUrl}/rx-master/get-inventorytransactionbyponumber`, options).pipe(map(response => response));
  }
  GetAllReceivedOrderDetails() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    //const parameters = new HttpParams().set('ponumber', ponumber);
    const options = {
      headers: headers
      //params: parameters
    };
    return this.http.get<any>(`${environment.apiUrl}/rx-master/get-allreceivedorder`, options).pipe(map(response => response));
  }


  SaveReceiveOrder(inventoryqueuedtls: InventoryReorderQueued[]) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/rx-master/savereceiveorder`, inventoryqueuedtls, options).pipe(map(response => response));
  }
  GetSrxBarcodeDataByOPN(OPNID: any) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const parameters = new HttpParams().set('opnid', OPNID);
    const options = {
      headers: headers,
      params: parameters
    };
    return this.http.get<any>(`${environment.apiUrl}/rx-master/getsrxbarcodedatabyopnid`, options).pipe(map(response => response));
  }
  GetRxmasterDataByOPN(OPNID: any) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const parameters = new HttpParams().set('opnid', OPNID);
    const options = {
      headers: headers,
      params: parameters
    };
    return this.http.get<any>(`${environment.apiUrl}/rx-master/getrxmasterdatabyopnid`, options).pipe(map(response => response));
  }
  RecordItemUsageSubmit(rcusage: recorditemusage) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const options = {
      headers: headers
    };

    //return this.http.post<any>(`${environment.apiUrl}/rx-master/recorditemusagesubmit`, rcusage, options).pipe(map(response => response));
    return this.http.post<any>(`${environment.apiUrl}/api/apibase/RecordItemUsageSubmit`, rcusage, options).pipe(map(response => response));
  }

  SavePatientDetails(recorditemusage: recorditemusage) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    //inventorytransaction.rxmodel[0] = rxmodel;
    const options = {
      headers: headers
    };
    return this.http.post<any>(`${environment.apiUrl}/rx-master/savepatientdetails`, recorditemusage, options).pipe(map(response => response));
  }
  GetPatientDetail(pid: string) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const parameters = new HttpParams().set("pid", pid);
    const options = {
      headers: headers,
      params: parameters
    };
    return this.http.get<any>(`${environment.apiUrl}/rx-master/get-patient-detail`, options).pipe(map(response => response));
  }
  DeleteRecordForReceiveOrder(DtlID: any) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const parameters = new HttpParams().set("DtlID", DtlID);

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/rx-master/DeleteRecordForReceiveOrder`, options).pipe(map(response => response));
  }





}
