export class Profile {
  UserId?: number;
  FName?: string;
  LName?: string;
  Username?: string;
  EmailId?: string;
  Title?: string;
  ProfilePhotoName: string;

}
