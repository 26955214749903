import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Printer } from '../../_models';
import { CommonService } from '../../_services/common.service';
import { MastersService } from '../../_services/masters.service';
import { UtilityServices } from '../../_helpers';
import { WhiteSpaceValidator } from '../../_helpers/_validations/must-match';

declare function ShowMessage(config): any;
declare function InitBasicDataTable(selector, title): any;
declare var $: any;
declare function AddRemoveClass(selector): any;

@Component({
  selector: 'app-printer',
  templateUrl: './printer.component.html',
  styleUrls: ['./printer.component.css']
})
export class PrinterComponent implements OnInit {
  private printersList: Printer[];
  private printer: Printer;
  IsEdit: boolean = false;
  IsErrorOccurred = false;
  changeId: number;
  rowClicked: any;
  printerForm: FormGroup;
  addPrinterForm: FormGroup;
  showDiv: boolean = false;
  constructor(private formBuilder: FormBuilder, private masterService: MastersService, private commonService: CommonService, private utilityServices: UtilityServices) {
    this.GetAllPrinters();
  }

  ngOnInit() {
    this.printerForm = this.formBuilder.group({
      ID: [null],
      PrinterName: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      Active: [null, Validators.required]
    });
    this.disableEdit();
    this.addPrinterForm = this.formBuilder.group({
      ID: [null],
      PrinterName: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      Active: [1, Validators.required]
    });
  }
  InitDataTable() {
    InitBasicDataTable("#SRX_PrinterMaster", "SRX_PrinterMaster");
  }
  AddRemoveClass() {

    AddRemoveClass("#addnewtab");
    $('.modal-dialog').draggable({
      handle: ".modal-content"
    });
  }
  get f() {
    return this.printerForm.controls;
  }
  get a() {
    return this.addPrinterForm.controls;
  }
  GetAllPrinters() {

    this.masterService.GetAllPrinters().subscribe((res: any) => {
      $('#SRX_PrinterMaster').dataTable().fnClearTable();
      $('#SRX_PrinterMaster').dataTable().fnDestroy();
      this.printersList = [];
      this.printersList = res.data;
      if (this.printersList.length > 0) {

        if (this.changeId >= 1 && this.printersList.find(o => o.id == this.changeId) != null) {
          this.printer = this.printersList.find(o => o.id == this.changeId);
          this.changeTableRowColor(this.printersList.findIndex(o => o.id == this.changeId));
          // $("#SRX_PrinterMaster").DataTable().row(this.printersList.findIndex(o => o.id == this.changeId)).scrollTo();
        }
        else {
          this.printer = this.printersList[0];
          this.changeTableRowColor(0);
        }
        this.showDiv = true;
        this.changeId = this.printer.id;
      }
      else {
        this.showDiv = false;
      }
    });
  }
  changeTableRowColor(index: any) {
    this.rowClicked = index;
  }
  GetDetails(printers) {

    this.printer = printers;
    this.changeId = this.printer.id;
    this.disableEdit();
  }
  enableEdit() {
    this.printerForm.enable();
    this.IsEdit = true;
    if (this.printer != null) {
      this.changeId = this.printer.id;
    }
  }
  disableEdit() {

    this.printerForm.disable();
    this.IsEdit = false;
    if (this.changeId != null && this.printersList != null) {
      this.GetAllPrinters();
    }
  }
  ResetAddForm() {
    this.addPrinterForm.reset();
    this.addPrinterForm.controls['Active'].patchValue(1);
  }
  edit() {
    this.utilityServices.startLoader();
    //this.ValidateInput(this.f.ID.value, 'PrinterName', this.f.PrinterName.value, 'PrinterName', 1);
    //if (this.IsErrorOccurred) {
    //  this.utilityServices.stopLoader();
    //  return;
    //}
    if (this.printerForm.invalid) {
      this.printerForm.markAllAsTouched();
      this.utilityServices.stopLoader();
      //ShowMessage({ type: "error", message: "Please fill form properly" });
      return;
    }
    this.commonService.ValidateInput("Printer", "ID", this.f.ID.value, 'PrinterName', this.f.PrinterName.value).subscribe((res: any) => {
      if (res.isSuccess) {
        if (res.data == true) {
          this.IsErrorOccurred = true;
          ShowMessage({ type: "error", message: "Printer Name '" + this.f.PrinterName.value + "' already exists." });
          this.utilityServices.stopLoader();
        }
        else {
          this.IsErrorOccurred = false;
          const mo: Printer = new Printer();
          mo.id = this.f.ID.value;
          mo.printerName = this.f.PrinterName.value;
          mo.active = this.f.Active.value;

          this.changeId = 0;
          this.masterService.SavePrinterData(mo).subscribe((res: any) => {
            if (res.isSuccess) {
              this.changeId = res.data;
              this.IsEdit = false;
              this.addPrinterForm.reset();
              this.addPrinterForm.controls['Active'].setValue(1);
              this.GetAllPrinters();

              this.disableEdit();
              this.utilityServices.stopLoader();
              ShowMessage({ type: "success", message: res.message });
            }
            else {
              this.utilityServices.stopLoader();
              ShowMessage({
                type: "error", message: res.message
              });
            }
          }, (err) => {
            this.utilityServices.stopLoader();
            ShowMessage({ type: "error", message: "Something went wrong..." });
          });
          this.utilityServices.stopLoader();
        }
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });

    //const mo: Printer = new Printer();
    //mo.id = this.f.ID.value;
    //mo.printerName = this.f.PrinterName.value;
    //mo.active = this.f.Active.value;

    //this.changeId = 0;
    //this.masterService.SavePrinterData(mo).subscribe((res: any) => {
    //  if (res.isSuccess) {
    //    this.changeId = res.data;
    //    this.IsEdit = false;
    //    this.addPrinterForm.reset();
    //    this.addPrinterForm.controls['Active'].setValue(1);
    //    this.GetAllPrinters();
        
    //    this.disableEdit();
    //    this.utilityServices.stopLoader();
    //    ShowMessage({ type: "success", message: res.message });
    //  }
    //  else {
    //    this.utilityServices.stopLoader();
    //    ShowMessage({
    //      type: "error", message: res.message
    //    });
    //  }
    //}, (err) => {
    //  this.utilityServices.stopLoader();
    //  ShowMessage({ type: "error", message: "Something went wrong..." });
    //});

  }
  add() {
    this.utilityServices.startLoader();
    //this.ValidateInput('0', 'PrinterName', this.a.PrinterName.value, 'PrinterName', 2);
    //if (this.IsErrorOccurred) {
    //  this.utilityServices.stopLoader();
    //  return;
    //}
    if (this.addPrinterForm.invalid) {
      this.addPrinterForm.markAllAsTouched();
      this.utilityServices.stopLoader();
      //ShowMessage({ type: "error", message: "Please fill form properly" });
      return;
    }
    this.commonService.ValidateInput("Printer", "ID", "0", 'PrinterName', this.a.PrinterName.value).subscribe((res: any) => {
      if (res.isSuccess) {
        if (res.data == true) {
          this.IsErrorOccurred = true;
          ShowMessage({ type: "error", message: "Printer Name '" + this.a.PrinterName.value + "' already exists." });
          this.utilityServices.stopLoader();
        }
        else {
          this.IsErrorOccurred = false;
          const mo: Printer = new Printer();
          mo.id = 0;
          mo.printerName = this.a.PrinterName.value;
          mo.active = this.a.Active.value;


          this.changeId = 0;
          this.masterService.SavePrinterData(mo).subscribe((res: any) => {
            if (res.isSuccess) {
              this.changeId = res.data;
              this.addPrinterForm.reset();
              this.addPrinterForm.controls['Active'].setValue(1);
              this.GetAllPrinters();
              this.disableEdit();
              this.addPrinterForm.reset();
              this.addPrinterForm.controls['Active'].patchValue(1);
              $("#default-example-modal-lg-center").modal('hide');
              this.utilityServices.stopLoader();
              ShowMessage({ type: "success", message: res.message });
            }
            else {
              this.utilityServices.stopLoader();
              ShowMessage({
                type: "error", message: res.message
              });
            }
          }, (err) => {
            this.utilityServices.stopLoader();
            ShowMessage({ type: "error", message: "Something went wrong..." });
          });
          this.utilityServices.stopLoader();
        }
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });

    //const mo: Printer = new Printer();
    //mo.id = 0;
    //mo.printerName = this.a.PrinterName.value;
    //mo.active = this.a.Active.value;


    //this.changeId = 0;
    //this.masterService.SavePrinterData(mo).subscribe((res: any) => {
    //  if (res.isSuccess) {
    //    this.changeId = res.data;
    //    this.addPrinterForm.reset();
    //    this.addPrinterForm.controls['Active'].setValue(1);
    //    this.GetAllPrinters();
    //    this.disableEdit();
    //    this.addPrinterForm.reset();
    //    this.addPrinterForm.controls['Active'].patchValue(1);
    //    $("#default-example-modal-lg-center").modal('hide');
    //    this.utilityServices.stopLoader();
    //    ShowMessage({ type: "success", message: res.message });
    //  }
    //  else {
    //    this.utilityServices.stopLoader();
    //    ShowMessage({
    //      type: "error", message: res.message
    //    });
    //  }
    //}, (err) => {
    //  this.utilityServices.stopLoader();
    //  ShowMessage({ type: "error", message: "Something went wrong..." });
    //});

  }
  Remove(ID: number) {
    this.utilityServices.startLoader();
    this.masterService.RemovePrinter(ID.toString()).subscribe((res: any) => {
      if (res.isSuccess) {
        $("#SRX_PrinterMaster").DataTable().row(this.printersList.findIndex(o => o.id == ID)).remove();
        this.GetAllPrinters();
        this.utilityServices.stopLoader();
        ShowMessage({ type: "success", message: res.message });
      }
      else {
        this.utilityServices.stopLoader();
        ShowMessage({
          type: "error", message: res.message
        });
      }
    }, (err) => {
      this.utilityServices.stopLoader();
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
  }
  ConfirmDelete(ID: number) {
    var x = confirm("Are you sure want to delete this record?");
    if (x)
      this.Remove(ID);
    else
      return false;
  }
  ValidateInput(Id?: string, FieldName?: string, Value?: string, ControlName?: string, fn?: number) {
    this.commonService.ValidateInput("Printer", "ID", Id, FieldName, Value).subscribe((res: any) => {
      if (res.isSuccess) {
        if (res.data == true) {
          this.IsErrorOccurred = true;
          ShowMessage({ type: "error", message: "Printer Name '" + Value + "' already exists." });

          //if (fn == 1 && ControlName == "PrinterName") {
          //  this.printerForm.patchValue({
          //    PrinterName: ''
          //  });
          //}
          //else if (fn == 2 && ControlName == "PrinterName") {
          //  this.addPrinterForm.patchValue({
          //    PrinterName: ''
          //  });
          //}
        }
        else {
          this.IsErrorOccurred = false;
        }
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
  }

}
