import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RxSourceMaster } from '../../_models';
import { CommonService } from '../../_services/common.service';
import { MastersService } from '../../_services/masters.service';
import { UtilityServices } from '../../_helpers';
import { WhiteSpaceValidator } from '../../_helpers/_validations/must-match';

declare function ShowMessage(config): any;
declare function InitBasicDataTable(selector, title): any;
declare var $: any;
declare function AddRemoveClass(selector): any;

@Component({
  selector: 'app-rxsource',
  templateUrl: './rxsource.component.html',
  styleUrls: ['./rxsource.component.css']
})
export class RxsourceComponent implements OnInit {
  private rxSourceList: RxSourceMaster[];
  private rxSource: RxSourceMaster;
  IsEdit: boolean = false;
  showDiv: boolean = false;
  IsErrorOccurredSourceCodeID = false;
  IsErrorOccurredSourceCodeName = false;
  changeId: number;
  rowClicked: any;
  rxSourceForm: FormGroup;
  addRxSourceForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private masterService: MastersService, private commonService: CommonService, private utilityServices: UtilityServices) {

  }

  ngOnInit() {

    this.InitDataTable();
    this.rxSourceForm = this.formBuilder.group({
      Id: [null],
      SourceCodeID: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      SourceCodeName: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      SortOrder: [null, [Validators.required, Validators.pattern("^[0-9]*$")]],
      Active: [null, Validators.required]
    });
    this.disableEdit();
    this.addRxSourceForm = this.formBuilder.group({
      SourceCodeID: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      SourceCodeName: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      SortOrder: [1, [Validators.required, Validators.pattern("^[0-9]*$")]],
      Active: [1, Validators.required]
    });

    this.GetAllrxSource();
  }

  InitDataTable() {
    InitBasicDataTable("#SRX_RxSourceMaster", "SRX_RxSourceMaster");
  }
  AddRemoveClass() {
    AddRemoveClass("#addnewtab");
  }
  get f() {
    return this.rxSourceForm.controls;
  }
  get a() {
    return this.addRxSourceForm.controls;
  }
  ResetAddForm() {
    this.addRxSourceForm.reset();
    this.addRxSourceForm.controls['SortOrder'].patchValue(1);
    this.addRxSourceForm.controls['Active'].patchValue(1);
  }
  GetAllrxSource() {

    this.masterService.GetAllRxSource().subscribe((res: any) => {

      $('#SRX_RxSourceMaster').dataTable().fnClearTable();
      $('#SRX_RxSourceMaster').dataTable().fnDestroy();

      this.rxSourceList = [];
      this.rxSourceList = res.data;

      if (this.rxSourceList.length > 0) {
        if (this.changeId >= 1 && this.rxSourceList.find(o => o.id == this.changeId) != null) {
          this.rxSource = this.rxSourceList.find(o => o.id == this.changeId);
          this.changeTableRowColor(this.rxSourceList.findIndex(o => o.id == this.changeId));

        }
        else {
          this.rxSource = this.rxSourceList[0];
          this.changeTableRowColor(0);
        }
        this.showDiv = true;
        this.changeId = this.rxSource.id;
      }
      else {
        this.showDiv = false;
      }
    });
  }

  changeTableRowColor(index: any) {
    this.rowClicked = index;
  }
  GetDetails(rxsource) {
    this.rxSource = rxsource;
    this.changeId = this.rxSource.id;
    this.disableEdit();
  }
  enableEdit() {
    this.rxSourceForm.enable();
    this.IsEdit = true;
    if (this.rxSource != null) {
      this.changeId = this.rxSource.id;
    }
  }
  disableEdit() {
    this.rxSourceForm.disable();
    this.IsEdit = false;
    this.GetAllrxSource();
  }

  edit() {
    this.utilityServices.startLoader();
    //this.ValidateInput(this.f.Id.value, 'SourceCodeID', this.f.SourceCodeID.value, 'SourceCodeID', 1);
    //this.ValidateInput(this.f.Id.value, 'SourceCodeName', this.f.SourceCodeName.value, 'SourceCodeName', 1);
    //if (this.IsErrorOccurredSourceCodeID || this.IsErrorOccurredSourceCodeName) {
    //  this.utilityServices.stopLoader();
    //  return;
    //}
    if (this.rxSourceForm.invalid) {
      this.rxSourceForm.markAllAsTouched();
      this.utilityServices.stopLoader();
      //ShowMessage({ type: "error", message: "Please fill form properly" });
      return;
    }

    this.commonService.ValidateInput("RxSource", "ID", this.f.Id.value, "SourceCodeID", this.f.SourceCodeID.value).subscribe((res: any) => {
      if (res.isSuccess) {
        if (res.data == true) {
          this.IsErrorOccurredSourceCodeID = true;
          ShowMessage({
            type: "error", message: "Source code ID '" + this.f.SourceCodeID.value + "' already exists."
          });
          this.utilityServices.stopLoader();
        }
        else {
          this.IsErrorOccurredSourceCodeID = false;
          this.commonService.ValidateInput("RxSource", "ID", this.f.Id.value, "SourceCodeName", this.f.SourceCodeName.value).subscribe((res: any) => {
            if (res.isSuccess) {
              if (res.data == true) {
                this.IsErrorOccurredSourceCodeName = true;
                ShowMessage({ type: "error", message: "Source code name '" + this.f.SourceCodeName.value + "' already exists." });
                this.utilityServices.stopLoader();
              }
              else {
                this.IsErrorOccurredSourceCodeName = false;
                const mo: RxSourceMaster = new RxSourceMaster();
                mo.id = this.f.Id.value;
                mo.sourceCodeID = this.f.SourceCodeID.value;
                mo.sourceCodeName = this.f.SourceCodeName.value;
                mo.sortOrder = this.f.SortOrder.value;
                mo.active = this.f.Active.value;

                this.changeId = 0;
                this.masterService.SaveRxSourceData(mo).subscribe((res: any) => {
                  if (res.isSuccess) {
                    this.changeId = res.data;
                    this.IsEdit = false;
                    this.addRxSourceForm.reset();
                    this.addRxSourceForm.controls['Active'].setValue(1);
                    this.GetAllrxSource();
                    this.disableEdit();
                    this.utilityServices.stopLoader();
                    ShowMessage({ type: "success", message: res.message });
                  }
                  else {
                    this.utilityServices.stopLoader();
                    ShowMessage({
                      type: "error", message: res.message
                    });
                  }
                }, (err) => {
                  this.utilityServices.stopLoader();
                  console.log(err);
                  ShowMessage({ type: "error", message: "Something went wrong..." });
                });
                this.utilityServices.stopLoader();
              }
            }
          }, (err) => {
            ShowMessage({ type: "error", message: "Something went wrong..." });
          });
        }
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });

    //const mo: RxSourceMaster = new RxSourceMaster();
    //mo.id = this.f.Id.value;
    //mo.sourceCodeID = this.f.SourceCodeID.value;
    //mo.sourceCodeName = this.f.SourceCodeName.value;
    //mo.sortOrder = this.f.SortOrder.value;
    //mo.active = this.f.Active.value;

    //this.changeId = 0;
    //this.masterService.SaveRxSourceData(mo).subscribe((res: any) => {
    //  if (res.isSuccess) {
    //    this.changeId = res.data;
    //    this.IsEdit = false;
    //    this.addRxSourceForm.reset();
    //    this.addRxSourceForm.controls['Active'].setValue(1);
    //    this.GetAllrxSource();
    //    this.disableEdit();
    //    this.utilityServices.stopLoader();
    //    ShowMessage({ type: "success", message: res.message });
    //  }
    //  else {
    //    this.utilityServices.stopLoader();
    //    ShowMessage({
    //      type: "error", message: res.message
    //    });
    //  }
    //}, (err) => {
    //  this.utilityServices.stopLoader();
    //  console.log(err);
    //  ShowMessage({ type: "error", message: "Something went wrong..." });
    //});

  }
  add() {
    this.utilityServices.startLoader();
    //this.ValidateInput('0', 'SourceCodeID', this.a.SourceCodeID.value, 'SourceCodeID', 2);
    //this.ValidateInput('0', 'SourceCodeName', this.a.SourceCodeName.value, 'SourceCodeName', 2);
    //if (this.IsErrorOccurredSourceCodeID || this.IsErrorOccurredSourceCodeName) {
    //  this.utilityServices.stopLoader();
    //  return;
    //}
    if (this.addRxSourceForm.invalid) {
      this.addRxSourceForm.markAllAsTouched();
      this.utilityServices.stopLoader();
      //ShowMessage({ type: "error", message: "Please fill form properly" });
      return;
    }
    this.commonService.ValidateInput("RxSource", "ID", "0", "SourceCodeID", this.a.SourceCodeID.value).subscribe((res: any) => {
      if (res.isSuccess) {
        if (res.data == true) {
            this.IsErrorOccurredSourceCodeID = true;
            ShowMessage({
              type: "error", message: "Source code ID '" + this.a.SourceCodeID.value + "' already exists."
            });
          this.utilityServices.stopLoader();
        }
        else {
          this.IsErrorOccurredSourceCodeID = false;
          this.commonService.ValidateInput("RxSource", "ID", "0", "SourceCodeName", this.a.SourceCodeName.value).subscribe((res: any) => {
            if (res.isSuccess) {
              if (res.data == true) {
                  this.IsErrorOccurredSourceCodeName = true;
                ShowMessage({ type: "error", message: "Source code name '" + this.a.SourceCodeName.value + "' already exists." });
                this.utilityServices.stopLoader();
               }
              else {
                this.IsErrorOccurredSourceCodeName = false;
                const mo: RxSourceMaster = new RxSourceMaster();
                mo.id = 0;
                mo.sourceCodeID = this.a.SourceCodeID.value;
                mo.sourceCodeName = this.a.SourceCodeName.value;

                mo.sortOrder = this.a.SortOrder.value;
                mo.active = this.a.Active.value;


                this.changeId = 0;
                this.masterService.SaveRxSourceData(mo).subscribe((res: any) => {
                  if (res.isSuccess) {
                    this.changeId = res.data;
                    this.addRxSourceForm.reset();
                    this.addRxSourceForm.controls['Active'].setValue(1);

                    this.GetAllrxSource();
                    this.disableEdit();
                    $("#default-example-modal-lg-center").modal('hide');
                    this.utilityServices.stopLoader();
                    ShowMessage({ type: "success", message: res.message });
                  }
                  else {
                    this.utilityServices.stopLoader();
                    ShowMessage({
                      type: "error", message: res.message
                    });
                  }
                }, (err) => {
                  this.utilityServices.stopLoader();
                  ShowMessage({ type: "error", message: "Something went wrong..." });
                });
                this.utilityServices.stopLoader();
                }
            }
          }, (err) => {
            ShowMessage({ type: "error", message: "Something went wrong..." });
          });
        }
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });


    //const mo: RxSourceMaster = new RxSourceMaster();
    //mo.id = 0;
    //mo.sourceCodeID = this.a.SourceCodeID.value;
    //mo.sourceCodeName = this.a.SourceCodeName.value;

    //mo.sortOrder = this.a.SortOrder.value;
    //mo.active = this.a.Active.value;


    //this.changeId = 0;
    //this.masterService.SaveRxSourceData(mo).subscribe((res: any) => {
    //  if (res.isSuccess) {
    //    this.changeId = res.data;
    //    this.addRxSourceForm.reset();
    //    this.addRxSourceForm.controls['Active'].setValue(1);

    //    this.GetAllrxSource();
    //    this.disableEdit();
    //    $("#default-example-modal-lg-center").modal('hide');
    //    this.utilityServices.stopLoader();
    //    ShowMessage({ type: "success", message: res.message });
    //  }
    //  else {
    //    this.utilityServices.stopLoader();
    //    ShowMessage({
    //      type: "error", message: res.message
    //    });
    //  }
    //}, (err) => {
    //  this.utilityServices.stopLoader();
    //  ShowMessage({ type: "error", message: "Something went wrong..." });
    //});

  }

  Remove(ID: number) {
    this.utilityServices.startLoader();
    this.masterService.RemoveRxSource(ID.toString()).subscribe((res: any) => {
      if (res.isSuccess) {
        $("#SRX_RxSourceMaster").DataTable().row(this.rxSourceList.findIndex(o => o.id == ID)).remove();
        this.GetAllrxSource();
        this.utilityServices.stopLoader();
        ShowMessage({ type: "success", message: res.message });
      }
      else {
        this.utilityServices.stopLoader();
        ShowMessage({
          type: "error", message: res.message
        });
      }
    }, (err) => {
      this.utilityServices.stopLoader();
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
  }
  ConfirmDelete(ID: number) {
    var x = confirm("Are you sure want to delete this record?");
    if (x)
      this.Remove(ID);
    else
      return false;
  }
  onlyNumbers(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 47 && k < 59));
  }
  ValidateInput(Id?: string, FieldName?: string, Value?: string, ControlName?: string, fn?: number) {
    this.commonService.ValidateInput("RxSource", "ID", Id, FieldName, Value).subscribe((res: any) => {
      if (res.isSuccess) {
        if (res.data == true) {
          if (ControlName == "SourceCodeID") {
            this.IsErrorOccurredSourceCodeID = true;
            ShowMessage({
              type: "error", message: "Source code ID '" + Value + "' already exists." });
          }
          if (ControlName == "SourceCodeName") {
            this.IsErrorOccurredSourceCodeName = true;
            ShowMessage({ type: "error", message: "Source code name '" + Value + "' already exists." });
          }
          //if (fn == 1 && ControlName == "SourceCodeID") {
          //  this.rxSourceForm.patchValue({
          //    SourceCodeID: ''
          //  });
          //}
          //else if (fn == 2 && ControlName == "SourceCodeID") {
          //  this.addRxSourceForm.patchValue({
          //    SourceCodeID: ''
          //  });
          //}
          //else if (fn == 1 && ControlName == "SourceCodeName") {
          //  this.rxSourceForm.patchValue({
          //    SourceCodeName: ''
          //  });
          //}
          //else if (fn == 2 && ControlName == "SourceCodeName") {
          //  this.addRxSourceForm.patchValue({
          //    SourceCodeName: ''
          //  });
          //}
        }
        else {
          if (ControlName == "SourceCodeID") {
            this.IsErrorOccurredSourceCodeID = false;
          }
          if (ControlName == "SourceCodeName") {
            this.IsErrorOccurredSourceCodeName = false;
          }
        }
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
  }
}
