import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services';

@Component({
  selector: 'app-static-layout',
  templateUrl: './static-layout.component.html',
  styleUrls: ['./static-layout.component.css']
})

export class StaticLayoutComponent implements OnInit {
  public version: string;

  currentYear: number = new Date().getFullYear();
  constructor(private userService: UserService) {
   
  }
  ngOnInit() {
    this.userService.GetAppsettingJsonInfo().subscribe((res: any) => {
      this.version = res.data;
    });
  }
}
