import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '.';
import { environment } from '../../environments/environment';
import { Profile, SavePermission, User } from '../_models';
import { ChangePassword } from '../_models/changepassword';
import { RoleMaster } from '../_models/rolemaster';
import { UserImports } from '../_models/userimports';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {
  private currentUserSubject: BehaviorSubject<User>;
  //private userAccessToken: string;
  constructor(private http: HttpClient,
    private authenticationService: AuthenticationService) {
    //if (this.authenticationService.currentUserValue) {
    //  this.userAccessToken = this.authenticationService.userAccessToken;
    //}
  }
  userAccessToken() {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    return this.currentUserSubject.value.access_token;
    
  }
  GetAll() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken());
    const options = {
      headers: headers
    };
    return this.http.get<any>(`${environment.apiUrl}/user-master/get-all`, options).pipe(map(response => response));
  }

    getById(id: number) {
      return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
  }

  ChangePassword(changepassword: ChangePassword) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken());

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/user-master/changepassword`, changepassword, options).pipe(map(response => response));
  }

  IsPasswordMatch(OldPassword: string, UserId: number) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken());

    const options = {
      headers: headers
    };
    var formData: any = new FormData();
    formData.append("OldPassword", OldPassword);
    formData.append("UserId", UserId);
    return this.http.post<any>(`${environment.apiUrl}/user-master/Ispasswordmatch`, formData, options).pipe(map(response => response));
  }
  SaveUserData(users: User) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken());

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/user-master/saveuserdata`, users, options).pipe(map(response => response));
  }

  ImportUserData(userimport: UserImports[]) {
    
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken());
    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/user-master/importuserdata`, userimport, options).pipe(map(response => response));
  }
  GetProfile() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken());
    const options = {
      headers: headers
    };
    return this.http.get<any>(`${environment.apiUrl}/user-auth/get-profile`, options).pipe(map(response => response));
  }
  UploadProfilePhoto(files: File[]) {
   
  const headers = new HttpHeaders().set('Authorization', this.userAccessToken());
     
  const options = {
    headers: headers
    };

    let formData: FormData = new FormData(),
      xhr: XMLHttpRequest = new XMLHttpRequest();

    for (let i = 0; i < files.length; i++) {
      if (files[i] != null && files[i] != undefined) {
        formData.append("FileUpload", files[i], files[i].name);
      }
    }

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
         // resolve(String(xhr.response));
        } else {
        //  reject(xhr.response);
        }
       // this.setIsRunning(false);
      }
    };


    return this.http.post<any>(`${environment.apiUrl}/user-auth/upload-profilephoto`, formData, options).pipe(map(response => response));
  }
  EditProfile(model: Profile) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken());

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/user-auth/edit-profile`, model, options).pipe(map(response => response));
  }

  GetAllRoles() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken());
    const options = {
      headers: headers
    };
    return this.http.get<any>(`${environment.apiUrl}/user-master/get-all-roles`, options).pipe(map(response => response));
  }
  SaveRoleData(role: RoleMaster) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken());

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/user-master/saveroledata`, role, options).pipe(map(response => response));
  }
  RemoveRole(ID: string) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken());
    const options = {
      headers: headers
    };
    var formData: any = new FormData();
    formData.append("ID", ID);
    return this.http.post<any>(`${environment.apiUrl}/user-master/remove-role`, formData, options)
      .pipe(map(data => {
        return data;
      }));
  }
  SavePermissionData(model: SavePermission) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken());

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/user-master/savepermissiondata`, model, options).pipe(map(response => response));
  }
  GetSystemInfo() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken());
    const options = {
      headers: headers
    };
    return this.http.get<any>(`${environment.apiUrl}/user-auth/system-info`, options).pipe(map(response => response));
  }
  GetAppsettingJsonInfo() {
    const headers = new HttpHeaders();
    const options = {
      headers: headers
    };
    return this.http.get<any>(`${environment.apiUrl}/user-auth/read-appsettingjsoninfo`, options).pipe(map(response => response));
  }
}
