import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilityServices } from '../../_helpers';
import { WhiteSpaceValidator } from '../../_helpers/_validations/must-match';
import { EmailTemplate } from '../../_models/masters';
import { CommonService } from '../../_services/common.service';
import { MastersService } from '../../_services/masters.service';

declare function ShowMessage(config): any;
declare function InitBasicDataTable(selector, title): any;
declare var $: any;
declare function AddRemoveClass(selector): any;

@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.css']
})
export class EmailTemplateComponent implements OnInit {
  private templateList: EmailTemplate[];
  private template: EmailTemplate;
  IsEdit: boolean = false;
  changeId: number;
  rowClicked: any;
  templateForm: FormGroup;
  addTemplateForm: FormGroup;
  showDiv: boolean = false;

  constructor(private formBuilder: FormBuilder, private masterService: MastersService, private commonService: CommonService, private utilityServices: UtilityServices) {
  }

  ngOnInit() {
    this.InitDataTable();
    this.GetAllEmailTemplates();
    this.templateForm = this.formBuilder.group({
      ID: [null],
      TemplateName: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      Subject: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace ]],
      TemplateBody: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace ]],
    });
    this.disableEdit();
    this.addTemplateForm = this.formBuilder.group({
      TemplateName: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace ]],
      Subject: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace ]],
      TemplateBody: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
    });
  }
  InitDataTable() {
    InitBasicDataTable("#SRX_EmailTemplate","SRX_EmailTemplate");
  }
  AddRemoveClass() {
    AddRemoveClass("#addnewtab");
  }
  get f() {
    return this.templateForm.controls;
  }
  get a() {
    return this.addTemplateForm.controls;
  }
  ResetAddForm() {
    this.addTemplateForm.reset();
  }
  GetAllEmailTemplates() {
   
    this.masterService.GetAllEmailTemplates().subscribe((res: any) => {
    
      $('#SRX_EmailTemplate').dataTable().fnClearTable();
      $('#SRX_EmailTemplate').dataTable().fnDestroy();
      this.templateList = [];
      this.templateList = res.data;

      if (this.templateList.length > 0) {
        if (this.changeId >= 1 && this.templateList.find(o => o.id == this.changeId) != null) {
          this.template = this.templateList.find(o => o.id == this.changeId);
          this.changeTableRowColor(this.templateList.findIndex(o => o.id == this.changeId));
        }
        else {
          this.template = this.templateList[0];
          this.changeTableRowColor(0);
        }
        this.showDiv = true;
        this.changeId = this.template.id;
      }
      else {
        this.showDiv = false;
      }
    });
  }
  changeTableRowColor(index: any) {
    this.rowClicked = index;
  }
  GetDetails(roles) {
    this.template = roles;
    this.changeId = this.template.id;
    this.disableEdit();
  }
  enableEdit() {
    this.templateForm.enable();
    this.IsEdit = true;
    if (this.template != null) {
      this.changeId = this.template.id;
    }
  }
  disableEdit() {
    this.templateForm.disable();
    this.IsEdit = false;
   
    if (this.changeId != null && this.templateList != null) {
      this.GetAllEmailTemplates();
    }
  }
  editUser() {
    if (this.templateForm.invalid) {
      this.templateForm.markAllAsTouched();
      //ShowMessage({ type: "error", message: "Please fill form properly" });
      return;
    }
    this.commonService.ValidateInput("EmailTemplate", "ID", this.f.ID.value, "TemplateName", this.f.TemplateName.value).subscribe((res: any) => {
      if (res.isSuccess) {
        if (res.data == true) {
          ShowMessage({ type: "error", message: "Template name '" + this.f.TemplateName.value + "' already exists." });
          this.utilityServices.stopLoader();
          //if (fn == 1 && ControlName == "TemplateName") {
          //  this.templateForm.patchValue({
          //    TemplateName: ''
          //  });
          //}
          //else if (fn == 2 && ControlName == "TemplateName") {
          //  this.addTemplateForm.patchValue({
          //    TemplateName: ''
          //  });
          //}
        }
        else {
          const ed: EmailTemplate = new EmailTemplate();
          ed.id = this.f.ID.value;
          ed.templateName = this.f.TemplateName.value;
          ed.subject = this.f.Subject.value;
          ed.templateBody = this.f.TemplateBody.value;

          this.changeId = 0;
          this.masterService.SaveEmailTemplateData(ed).subscribe((res: any) => {
            if (res.isSuccess) {
              this.changeId = res.data;
              this.IsEdit = false;
              this.addTemplateForm.reset();
              this.GetAllEmailTemplates();
              this.disableEdit();
              ShowMessage({ type: "success", message: res.message });
            }
            else {
              ShowMessage({
                type: "error", message: res.message
              });
            }
          }, (err) => {
            ShowMessage({ type: "error", message: "Something went wrong..." });
          });
          this.utilityServices.stopLoader();
        }
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
    //const ed: EmailTemplate = new EmailTemplate();
    //ed.id = this.f.ID.value;
    //ed.templateName = this.f.TemplateName.value;
    //ed.subject = this.f.Subject.value;
    //ed.templateBody = this.f.TemplateBody.value;

    //this.changeId = 0;
    //this.masterService.SaveEmailTemplateData(ed).subscribe((res: any) => {
    //  if (res.isSuccess) {
    //    this.changeId = res.data;
    //    this.IsEdit = false;
    //    this.addTemplateForm.reset();
    //    this.GetAllEmailTemplates();
    //    this.disableEdit();
    //    ShowMessage({ type: "success", message: res.message });
    //  }
    //  else {
    //    ShowMessage({
    //      type: "error", message: res.message
    //    });
    //  }
    //}, (err) => {
    //  ShowMessage({ type: "error", message: "Something went wrong..." });
    //});

  }
  add() {
    if (this.addTemplateForm.invalid) {
      this.addTemplateForm.markAllAsTouched();
      //ShowMessage({ type: "error", message: "Please fill form properly" });
      return;
    }
    this.commonService.ValidateInput("EmailTemplate", "ID", "0", "TemplateName", this.a.TemplateName.value).subscribe((res: any) => {
      if (res.isSuccess) {
        if (res.data == true) {
          ShowMessage({ type: "error", message: "Template name '" + this.a.TemplateName.value + "' already exists." });
          this.utilityServices.stopLoader();
          //if (fn == 1 && ControlName == "TemplateName") {
          //  this.templateForm.patchValue({
          //    TemplateName: ''
          //  });
          //}
          //else if (fn == 2 && ControlName == "TemplateName") {
          //  this.addTemplateForm.patchValue({
          //    TemplateName: ''
          //  });
          //}
        }
        else {
          const ed: EmailTemplate = new EmailTemplate();
          ed.id = 0;
          ed.templateName = this.a.TemplateName.value;
          ed.subject = this.a.Subject.value;
          ed.templateBody = this.a.TemplateBody.value;

          this.changeId = 0;
          this.masterService.SaveEmailTemplateData(ed).subscribe((res: any) => {
            if (res.isSuccess) {
              this.changeId = res.data;
              this.addTemplateForm.reset();
              this.GetAllEmailTemplates();
              this.disableEdit();
              $("#default-example-modal-lg-center").modal('hide');
              ShowMessage({ type: "success", message: res.message });
              this.utilityServices.stopLoader();
            }
            else {
              ShowMessage({
                type: "error", message: res.message
              });
              this.utilityServices.stopLoader();
            }
          }, (err) => {
            ShowMessage({ type: "error", message: "Something went wrong..." });
          });
          this.utilityServices.stopLoader();
        }
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });


    //const ed: EmailTemplate = new EmailTemplate();
    //ed.id = 0;
    //ed.templateName = this.a.TemplateName.value;
    //ed.subject = this.a.Subject.value;
    //ed.templateBody = this.a.TemplateBody.value;

    //this.changeId = 0;
    //this.masterService.SaveEmailTemplateData(ed).subscribe((res: any) => {
    //  if (res.isSuccess) {
    //    this.changeId = res.data;
    //    this.addTemplateForm.reset();
    //    this.GetAllEmailTemplates();
    //    this.disableEdit();
    //    $("#default-example-modal-lg-center").modal('hide');
    //    ShowMessage({ type: "success", message: res.message });
    //  }
    //  else {
    //    ShowMessage({
    //      type: "error", message: res.message
    //    });
    //  }
    //}, (err) => {
    //  ShowMessage({ type: "error", message: "Something went wrong..." });
    //});

  }
  onlyNumbers(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 47 && k < 59));
  }
  ValidateInput(Id?: string, FieldName?: string, Value?: string, ControlName?: string, fn?: number) {
    this.commonService.ValidateInput("EmailTemplate", "ID", Id, FieldName, Value).subscribe((res: any) => {
      if (res.isSuccess) {
        if (res.data == true) {
          ShowMessage({ type: "error", message: "Template name '" + Value + "' already exists." });

          //if (fn == 1 && ControlName == "TemplateName") {
          //  this.templateForm.patchValue({
          //    TemplateName: ''
          //  });
          //}
          //else if (fn == 2 && ControlName == "TemplateName") {
          //  this.addTemplateForm.patchValue({
          //    TemplateName: ''
          //  });
          //}
        }
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
  }

  ConfirmDelete(ID: number) {
    var x = confirm("Are you sure want to delete this record?");
    if (x)
      this.Remove(ID);
    else
      return false;
  }
  Remove(ID: number) {
    
    this.masterService.RemoveEmailTemplate(ID.toString()).subscribe((res: any) => {
      if (res.isSuccess) {
        $("#SRX_EmailTemplate").DataTable().row(this.templateList.findIndex(o => o.id == ID)).remove();

        this.GetAllEmailTemplates();
        ShowMessage({ type: "success", message: res.message });
      }
      else {
        ShowMessage({
          type: "error", message: res.message
        });
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
  }

}
