import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';
import { TimeZone } from '../_models';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private userAccessToken: string; 

  constructor(private http: HttpClient,
    private authenticationService: AuthenticationService) {
    if (this.authenticationService.currentUserValue) {
      this.userAccessToken = this.authenticationService.userAccessToken;
    }
  }

  GetTimeZones() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams();

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/common/get-timezones`, options).pipe(map(response => response));
  }
  GetSites() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams();

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/common/get-sites`, options).pipe(map(response => response));
  }
  GetPermissions() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };

    return this.http.get<any>(`${environment.apiUrl}/user-auth/get-permissions`, options).pipe(map(response => response));
  }

  GetMenu() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };

    return this.http.get<any>(`${environment.apiUrl}/user-auth/get-menu`, options).pipe(map(response => response));
  }

  GetUserType() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams();

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/common/get-usertype`, options).pipe(map(response => response));
  }

  GetClinicalRole() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams();

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/common/get-clinicalrole`, options).pipe(map(response => response));
  }
  GetCurrencyList() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams();

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/common/get-currency-list`, options).pipe(map(response => response));
  }
  GetProviderList() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams();

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/common/get-provider-list`, options).pipe(map(response => response));
  }
  GetProviderList_RIU() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams();

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/common/get-provider-list-riu`, options).pipe(map(response => response));
  }
  GetSelectedProviderList(UserId: number) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const parameters = new HttpParams().set('UserId', UserId.toString());

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/common/get-selected-provider-list`, options).pipe(map(response => response));
  }
  GetRandomSRXID(startCode : string) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams().set('startCode', startCode.toString());;

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/common/get-randomSRXid`, options).pipe(map(response => response));
  }
  GetConnectionString(clientid: string) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const parameters = new HttpParams().set('clientid', clientid.toString());;

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/common/get-connectionstring`, options).pipe(map(response => response));
  }
  
   ValidateInput(TableName?: string, UniqueFieldName?: string, Id?: string, FieldName?: string, Value?: string) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams()
      .set("TableName", TableName)
      .set("UniqueFieldName", UniqueFieldName)
      .set("Id", Id)
      .set("FieldName", FieldName)
      .set("Value", Value);

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/common/validate-input`, options).pipe(map(response => response));
  }
  // use for registration page
  ValidateInputWithOutAuthenticate(TableName?: string, UniqueFieldName?: string, Id?: string, FieldName?: string, Value?: string) {
    //const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const parameters = new HttpParams()
      .set("TableName", TableName)
      .set("UniqueFieldName", UniqueFieldName)
      .set("Id", Id)
      .set("FieldName", FieldName)
      .set("Value", Value);
    const options = {
      //headers: headers,
      params: parameters
    };
    return this.http.get<any>(`${environment.apiUrl}/common/validate-input-without-authenticate`, options).pipe(map(response => response));
  }
  ValidateInputVendorMaster(TableName?: string, UniqueFieldName?: string, Id?: string, FieldName?: string, Value?: string, VName?: string, Email?: string) {    
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams()
      .set("TableName", TableName)
      .set("UniqueFieldName", UniqueFieldName)
      .set("Id", Id)
      .set("FieldName", FieldName)
      .set("Value", Value)
      .set("VName", VName)
      .set("Email", Email);

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/common/validate-input-vendormaster`, options).pipe(map(response => response));
  }
  ValidatePassword(ForgotId?: string, Value?: string) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const parameters = new HttpParams()
      .set("ForgotId", ForgotId)
      .set("NewPassword", Value);

    const options = {
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/common/validate-password`, options).pipe(map(response => response));
  }
  GetAllLocations() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    return this.http.get<any>(`${environment.apiUrl}/common/get-all-locations`, options).pipe(map(response => response));
  }
  GetAdminSettingValuebyName(keyname : string) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const parameters = new HttpParams()
      .set("keyname", keyname);

    const options = {
      headers: headers,
       params: parameters
    };
    return this.http.get<any>(`${environment.apiUrl}/common/get-adminsettingsbykey`, options).pipe(map(response => response));
  }
  GetVendorsDropDown() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams();

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/common/get-vendors`, options).pipe(map(response => response));
  }
  GetAllRxType() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    return this.http.get<any>(`${environment.apiUrl}/common/get-rxtype`, options).pipe(map(response => response));
  }
  GetAllSource() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    return this.http.get<any>(`${environment.apiUrl}/common/get-Source`, options).pipe(map(response => response));
  }
  GetLoginReasonMaster() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams();

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/common/get-login-reason-master`, options).pipe(map(response => response));
  }
  GenerateApiKeyAndSecret(ClientId : string) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams().set('ClientId', ClientId.toString());;

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/common/get-apikey-secret`, options).pipe(map(response => response));
  }
  GetAPIToken(ApiKey: string, Secret: string) {

    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams().set('Apikey', ApiKey.toString())
      .set('Secret', Secret.toString());

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/api/apibase/GetApiToken`, options).pipe(map(response => response));
  }
  GetPatientIDList() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const parameters = new HttpParams();
    const options = {
      headers: headers,
      params: parameters
    };
    return this.http.get<any>(`${environment.apiUrl}/common/get-patientid-list`, options).pipe(map(response => response));
  }
}
