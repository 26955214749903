import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmailTemplateComponent } from './email-template/email-template.component';
import { LabelComponent } from './label/label.component';
import { LocationComponent } from './location/location.component';
import { OrderingProductMasterComponent } from './ordering-product-master/ordering-product-master.component';
import { PrinterComponent } from './printer/printer.component';
import { RxTypeComponent } from './rx-type/rx-type.component';
import { RxsourceComponent } from './rxsource/rxsource.component';
import { SettingsComponent } from './settings/settings.component';
import { UnitComponent } from './unit/unit.component';
import { VendorMasterComponent } from './vendor-master/vendor-master.component';
import { VfcComponent } from './vfc/vfc.component';


const routes: Routes = [{
  path: 'rx-type',
  component: RxTypeComponent
},
{
  path: 'unit',
  component: UnitComponent
},
{
  path: 'location',
  component: LocationComponent
  },
  {
    path: 'printer',
    component: PrinterComponent
  },
  {
    path: 'settings',
    component: SettingsComponent
  },
  {
    path: 'vfc',
    component: VfcComponent
  },
  {
    path: 'label',
    component: LabelComponent
  },
  {
    path: 'vendor-master',
    component: VendorMasterComponent
  },
  {
    path: 'ordering-product-master',
    component: OrderingProductMasterComponent
  },
  {
    path: 'email-template',
    component: EmailTemplateComponent
  },
  {
    path: 'rx-source',
    component: RxsourceComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MastersRoutingModule { }
