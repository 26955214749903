import { Directive, Input } from '@angular/core';

@Directive({
  selector: 'appSidebarMenu'
})
export class SidebarMenuDirective {
  constructor() { }
  @Input() menuList: any; 

}
