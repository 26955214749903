import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';
import { RxTypeMaster, UnitMaster, ClientLocations, Printer, AdminSettings, VendorMaster, OrderingProductMaster, OrderingProductVendorPricing, EmailTemplate, updateAdminSettings } from '../_models';
import { map } from 'rxjs/operators';
import { NDCMaster } from '../_models/ndcmaster';
import { NDCImports } from '../_models/ndcimports';

@Injectable({
  providedIn: 'root'
})
export class MastersService {
  private userAccessToken: string;
  constructor(private http: HttpClient,
    private authenticationService: AuthenticationService) {
    if (this.authenticationService.currentUserValue) {
      this.userAccessToken = this.authenticationService.userAccessToken;
    }
  }
  GetAllRxTypes() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    return this.http.get<any>(`${environment.apiUrl}/masters/get-all-rxtypes`, options).pipe(map(response => response));
  }
  SaveRxTypeData(model: RxTypeMaster) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/masters/saverxtypedata`, model, options).pipe(map(response => response));
  }
  RemoveRxType(ID: string) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    var formData: any = new FormData();
    formData.append("ID", ID);
    return this.http.post<any>(`${environment.apiUrl}/masters/remove-rxtype`, formData, options)
      .pipe(map(data => {
        return data;
      }));
  }
  GetAllRxSource() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    return this.http.get<any>(`${environment.apiUrl}/masters/get-all-rxsource`, options).pipe(map(response => response));
  }

  SaveRxSourceData(model: RxTypeMaster) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/masters/save-rxsourcedata`, model, options).pipe(map(response => response));
  }

  RemoveRxSource(ID: string) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    var formData: any = new FormData();
    formData.append("ID", ID);
    return this.http.post<any>(`${environment.apiUrl}/masters/remove-rxsource`, formData, options)
      .pipe(map(data => {
        return data;
      }));
  }

  GetAllUnits() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    return this.http.get<any>(`${environment.apiUrl}/masters/get-all-units`, options).pipe(map(response => response));
  }
  SaveUnitData(model: UnitMaster) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/masters/saveunitdata`, model, options).pipe(map(response => response));
  }
  RemoveUnit(ID: string) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
  
    var formData: any = new FormData();
    formData.append("ID", ID);
    return this.http.post<any>(`${environment.apiUrl}/masters/remove-unit`, formData, options)
      .pipe(map(data => {
        return data;
      }));
  }
  GetAllNDCMaster() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    return this.http.get<any>(`${environment.apiUrl}/ndc-master/get-all-ndc-master`, options).pipe(map(response => response));
  }
  SaveNDCMasterData(model: NDCMaster) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/ndc-master/savendcmasterdata`, model, options).pipe(map(response => response));
  }
  RemoveNDC(ID: string) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    var formData: any = new FormData();
    formData.append("ID", ID);
    return this.http.post<any>(`${environment.apiUrl}/ndc-master/remove-ndc`, formData, options)
      .pipe(map(data => {
        return data;
      }));
  }

  ImportNDCData(ndcimport: NDCImports[]) {    
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/ndc-master/importndcdata`, ndcimport, options).pipe(map(response => response));
  }

  GetAllLocations() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    return this.http.get<any>(`${environment.apiUrl}/masters/get-all-locations`, options).pipe(map(response => response));
  }
  
  
  SaveLocationData(model: ClientLocations) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/masters/savelocationdata`, model, options).pipe(map(response => response));
  }
  RemoveLocation(ID: string) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    var formData: any = new FormData();
    formData.append("ID", ID);
    return this.http.post<any>(`${environment.apiUrl}/masters/remove-location`, formData, options)
      .pipe(map(data => {
        return data;
      }));
  }
  GetAllPrinters() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    return this.http.get<any>(`${environment.apiUrl}/masters/get-all-printers`, options).pipe(map(response => response));
  }
  SavePrinterData(model: Printer) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/masters/saveprinterdata`, model, options).pipe(map(response => response));
  }
  RemovePrinter(ID: string) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    
    var formData: any = new FormData();
    formData.append("ID", ID);
    return this.http.post<any>(`${environment.apiUrl}/masters/remove-printer`, formData, options)
      .pipe(map(data => {
        return data;
      }));
  }
  GetAllSettings() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    return this.http.get<any>(`${environment.apiUrl}/masters/get-all-settings`, options).pipe(map(response => response));
  }
  SaveSettingData(model: AdminSettings) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/masters/savesettingdata`, model, options).pipe(map(response => response));
  }
  RemoveSettingData(id: number) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/masters/removesettingdata`, id, options).pipe(map(response => response));
  }
  GetAllVFCs() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    return this.http.get<any>(`${environment.apiUrl}/masters/get-all-vfcs`, options).pipe(map(response => response));
  }
  SaveVFCData(model: AdminSettings) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/masters/savevfcdata`, model, options).pipe(map(response => response));
  }
  RemoveVFC(ID: string) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
   
    var formData: any = new FormData();
    formData.append("ID", ID);
    return this.http.post<any>(`${environment.apiUrl}/masters/remove-vfc`, formData, options)
      .pipe(map(data => {
        return data;
      }));
  }
  GetAllLabels() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    return this.http.get<any>(`${environment.apiUrl}/masters/get-all-labels`, options).pipe(map(response => response));
  }
  SaveLabelData(model: Printer) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/masters/savelabeldata`, model, options).pipe(map(response => response));
  }
  RemoveLabel(ID: string) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    var formData: any = new FormData();
    formData.append("ID", ID);
    return this.http.post<any>(`${environment.apiUrl}/masters/remove-label`, formData, options)
      .pipe(map(data => {
        return data;
      }));
  }
  GetVendorMasters() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    return this.http.get<any>(`${environment.apiUrl}/masters/get-all-vendormasters`, options).pipe(map(response => response));
  }
  GetVendorMastersForReceiveOrder() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    return this.http.get<any>(`${environment.apiUrl}/masters/get-all-vendormasters-for-receiveorder`, options).pipe(map(response => response));
  }
  SaveVendorMasterData(model: VendorMaster) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/masters/savevendormasterdata`, model, options).pipe(map(response => response));
  }
  SaveVendorMasterDataFromPO(model: VendorMaster) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/masters/savevendormasterdatafromPO`, model, options).pipe(map(response => response));
  }
  RemoveVendor(ID: string) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
   
    var formData: any = new FormData();
    formData.append("ID", ID);
    return this.http.post<any>(`${environment.apiUrl}/masters/remove-vendor`, formData, options)
      .pipe(map(data => {
        return data;
      }));
  }
  GetAllOrderingProductMasters() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    return this.http.get<any>(`${environment.apiUrl}/masters/get-all-ordering-product-masters`, options).pipe(map(response => response));
  }
  GetAllActiveOrderingProductMasters() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const parameters = new HttpParams()
      .set("OnlyActiveOrderingProductNo", "true");
    const options = {
      headers: headers,
      params: parameters
    };
    return this.http.get<any>(`${environment.apiUrl}/masters/get-all-ordering-product-masters`, options).pipe(map(response => response));
  }
  GetOrderingProductMstByID(OrderingProductID: any) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const parameters = new HttpParams()
      .set("OrderingProductID", OrderingProductID.toString());

    const options = {
      headers: headers,
      params: parameters
    };
    return this.http.get<any>(`${environment.apiUrl}/masters/get-ordering-product-byid`, options).pipe(map(response => response));
  }
  GetRxMasterByOrderingId(OrderingProductID: any) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const parameters = new HttpParams()
      .set("OrderingProductID", OrderingProductID.toString());

    const options = {
      headers: headers,
      params: parameters
    };
    return this.http.get<any>(`${environment.apiUrl}/masters/get-rxmaster-byid`, options).pipe(map(response => response));
  }
  
  SaveOrderingProductMasterData(model: OrderingProductMaster) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/masters/saveorderingproductmasterdata`, model, options).pipe(map(response => response));
  }
  IsOrderingProductNoInUse(OrderingProductID: any) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const parameters = new HttpParams()
      .set("OrderingProductID", OrderingProductID)

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/masters/IsOrderingProductNoInUse`, options).pipe(map(response => response));
  }
  RemoveOrderingProduct(ID: string) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    
    var formData: any = new FormData();
    formData.append("ID", ID);
    return this.http.post<any>(`${environment.apiUrl}/masters/remove-ordering-product`, formData, options)
      .pipe(map(data => {
        return data;
      }));
  }
  RemoveVendorPricing(OVPID: number) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };

    var formData: any = new FormData();
    formData.append("ID", OVPID);
    return this.http.post<any>(`${environment.apiUrl}/masters/remove-vendor-pricing`, formData, options)
      .pipe(map(data => {
        return data;
      }));
  }
  GetTransactionTypes() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    return this.http.get<any>(`${environment.apiUrl}/masters/get-transactiontype`, options).pipe(map(response => response));
  }
  GetInventoryOrederQueuedDtls() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    return this.http.get<any>(`${environment.apiUrl}/masters/get-inventoryreorderqueueddtls`, options).pipe(map(response => response));
  }

  DeleteInventoryReorder(ID: any) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const options = {
      headers: headers
    };
    var formData: any = new FormData();
    formData.append("ID", ID);
    return this.http.post<any>(`${environment.apiUrl}/masters/delete-inventoryreorder`, formData, options)
      .pipe(map(data => {
        return data;
      }));
    //return this.http.post<any>(`${environment.apiUrl}/masters/delete-inventoryreorder`, irid, options).pipe(map(response => response));
  }

  GetVendorDetailsbyQuery() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    return this.http.get<any>(`${environment.apiUrl}/masters/get-vendordetailsbyquery`, options).pipe(map(response => response));
  }
  GetAllVendorPricings(OrderingProductID?: number) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams()
      .set("OrderingProductID", OrderingProductID.toString());

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/masters/get-all-vendor-pricings`, options).pipe(map(response => response));
  }
  SaveVendorPricingData(model: OrderingProductVendorPricing) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/masters/savevendorpricingdata`, model, options).pipe(map(response => response));
  }
  GetAllpurchaseOrderDetails() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    return this.http.get<any>(`${environment.apiUrl}/masters/get-all-purchaseorderlist`, options).pipe(map(response => response));
  }
  GetPurchaseOrderDetailsById(hdrId : any) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const parameters = new HttpParams().set('hdrId', hdrId);
    const options = {
      headers: headers,
       params: parameters
    };
    //return this.http.get<any>(`${environment.apiUrl}/masters/get-purchaseorderlistbyid/${hdrId}`);
    return this.http.get<any>(`${environment.apiUrl}/masters/get-purchaseorderlistbyid`, options).pipe(map(response => response));
  }
  GetPurchaseOrderEditDetailsById(hdrId: any) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const parameters = new HttpParams().set('hdrId', hdrId);
    const options = {
      headers: headers,
      params: parameters
    };
    //return this.http.get<any>(`${environment.apiUrl}/masters/get-purchaseorderlistbyid/${hdrId}`);
    return this.http.get<any>(`${environment.apiUrl}/masters/get-purchaseorderDtlEditlistbyid`, options).pipe(map(response => response));
  }
  GetProductNameById(location : string,orderingproductid: any) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const parameters = new HttpParams().set('orderingproductid', orderingproductid)
      .set('location', location);
    const options = {
      headers: headers,
      params: parameters
    };
    return this.http.get<any>(`${environment.apiUrl}/masters/get-productnamebyid`, options).pipe(map(response => response));
  }
  GetOrderListByVendorId(vendorid: any) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const parameters = new HttpParams().set('vendorid', vendorid);
    const options = {
      headers: headers,
      params: parameters
    };
    return this.http.get<any>(`${environment.apiUrl}/masters/get-orderbyvendorid`, options).pipe(map(response => response));
  }
  GetOrderigProductNoByVendorId(vendorid: any) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const parameters = new HttpParams().set('vendorid', vendorid);
    const options = {
      headers: headers,
      params: parameters
    };
    return this.http.get<any>(`${environment.apiUrl}/masters/get-orderingproductNobyvendorid`, options).pipe(map(response => response));
  }
  GetLotNoByOrderigProductNo() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
   // const parameters = new HttpParams().set('vendorid', ordProdId);
    const options = {
      headers: headers
     // params: parameters
    };
    return this.http.get<any>(`${environment.apiUrl}/masters/get-LotNoByorderingproductNo`, options).pipe(map(response => response));
  }

  GetExpDateByLotNo() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    // const parameters = new HttpParams().set('vendorid', ordProdId);
    const options = {
      headers: headers
      // params: parameters
    };
    return this.http.get<any>(`${environment.apiUrl}/masters/get-ExpDateByLotNo`, options).pipe(map(response => response));
  }
  
  GetAllEmailTemplates() {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    return this.http.get<any>(`${environment.apiUrl}/masters/get-all-email-templates`, options).pipe(map(response => response));
  }
  SaveEmailTemplateData(model: EmailTemplate) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/masters/saveemailtemplatedata`, model, options).pipe(map(response => response));
  }
  RemoveEmailTemplate(ID: string) {
   
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const options = {
      headers: headers
    };
    var formData: any = new FormData();
    formData.append("ID", ID);
    return this.http.post<any>(`${environment.apiUrl}/masters/remove-emailtemplate`, formData, options)
      .pipe(map(data => {
        return data;
      }));
  }
  GetDatabasesByDbId(DatabaseId: string) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const parameters = new HttpParams()
      .set("DatabaseId", DatabaseId);

    const options = {
      headers: headers,
      params: parameters
    };

    return this.http.get<any>(`${environment.apiUrl}/masters/get-database-bydbid`, options).pipe(map(response => response));
  }
  UpdateSettings(model: updateAdminSettings) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/masters/updatesettings`, model, options).pipe(map(response => response));
  }
  UpdateSettingsList(model: AdminSettings[]) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.apiUrl}/masters/updatesettingsCATEGORY`, model, options).pipe(map(response => response));
  }
}
