import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropDownModel, OrderingProductMaster, OrderingProductVendorPricing } from '../../_models';
import { InventoryUOM } from '../../_models/_inventory/inventoryuom';
import { CommonService } from '../../_services/common.service';
import { MastersService } from '../../_services/masters.service';
import { RxMasterServices } from '../../_services/_inventory/rxmaster.service';
import { UtilityServices } from '../../_helpers';
import { WhiteSpaceValidator } from '../../_helpers/_validations/must-match';

declare function ShowMessage(config): any;
declare function InitBasicDataTable(selector, title): any;
declare var $: any;
declare function AddRemoveClass(selector): any;

@Component({
  selector: 'app-ordering-product-master',
  templateUrl: './ordering-product-master.component.html',
  styleUrls: ['./ordering-product-master.component.css']
})
export class OrderingProductMasterComponent implements OnInit {
  private orderingProductMasterList: OrderingProductMaster[];
  private orderingProductMaster: OrderingProductMaster;
  IsEdit: boolean = false;
  IsErrorOccurred = false;
  changeId: number;
  rowClicked: any;
  orderingProductMasterForm: FormGroup;
  addOrderingProductMasterForm: FormGroup;
  vendorPricingForm: FormGroup;
  editVendorPricingForm: FormGroup;
  showDiv: boolean = false;
  showPriceingDiv: boolean = false;
  IsUsedUOM: boolean = false;
  private vendorPricingList: OrderingProductVendorPricing[];
  private vendorPricing: OrderingProductVendorPricing;
  private inventoryuoms: InventoryUOM[];
  private vendorsDropDown: DropDownModel[];
  constructor(private formBuilder: FormBuilder, private masterService: MastersService, private commonService: CommonService, private Rxmasterservices: RxMasterServices,
    private utilityServices: UtilityServices) {
    this.GetAllOrderingProductMasters();
    this.GetInventoryUOM();
    this.GetVendors();
  }

  ngOnInit() {
    this.orderingProductMasterForm = this.formBuilder.group({
      OrderingProductID: [null],
      OrderingProductNo: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      OrderingProductDescription: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      Itemuom: [null, Validators.required],
      // Purchaseuom: [null, Validators.required],
      //  Purconversionfactor: [null, Validators.required],
      Active: [null, Validators.required]
    });
    this.disableEdit();
    this.addOrderingProductMasterForm = this.formBuilder.group({
      OrderingProductNo: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      OrderingProductDescription: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      Itemuom: [null, Validators.required],
      // Purchaseuom: [null, Validators.required],
      // Purconversionfactor: [null, Validators.required],
      Active: [true, Validators.required]
    });
    this.vendorPricingForm = this.formBuilder.group({
      VendorID: [null, Validators.required],
      Price: [null, Validators.required],
      Selected: [false],
      Active: [1, Validators.required]
    });
    this.editVendorPricingForm = this.formBuilder.group({
      OVPID: [null],
      VendorID: [null, Validators.required],
      Price: [null, Validators.required],
      Selected: [null],
      Active: [null, Validators.required]
    });
  }

  InitDataTable() {
    InitBasicDataTable("#SRX_OrderingProductMaster", "SRX_OrderingProductMaster");
  }
  AddRemoveClass() {

    AddRemoveClass("#addnewtab");
  }
  AddEditVendorPricing() {

    AddRemoveClass("#VendorPricing");
  }
  EditVendorPricing(vendorPricing) {

    this.vendorPricing = vendorPricing;
    AddRemoveClass("#EditVendorPricing");
  }

  get f() {
    return this.orderingProductMasterForm.controls;
  }
  get a() {
    return this.addOrderingProductMasterForm.controls;
  }
  get v() {
    return this.vendorPricingForm.controls;
  }
  get e() {
    return this.editVendorPricingForm.controls;
  }
  ResetAddForm() {
    this.addOrderingProductMasterForm.reset();
    this.addOrderingProductMasterForm.controls['Active'].patchValue(true);
    this.vendorPricingForm.reset();
    this.vendorPricingForm.controls['Active'].patchValue(1);
  }
  GetAllOrderingProductMasters() {

    this.masterService.GetAllOrderingProductMasters().subscribe((res: any) => {
      $('#SRX_OrderingProductMaster').dataTable().fnClearTable();
      $('#SRX_OrderingProductMaster').dataTable().fnDestroy();
      this.orderingProductMasterList = [];
      this.orderingProductMasterList = res.data;

      if (this.orderingProductMasterList.length > 0) {
        if (this.changeId >= 1 && this.orderingProductMasterList.find(o => o.orderingProductID == this.changeId) != null) {
          this.orderingProductMaster = this.orderingProductMasterList.find(o => o.orderingProductID == this.changeId);
          this.changeTableRowColor(this.orderingProductMasterList.findIndex(o => o.orderingProductID == this.changeId));
          //$("#SRX_OrderingProductMaster").DataTable().row(this.orderingProductMasterList.findIndex(o => o.orderingProductID == this.changeId)).scrollTo();
        }
        else {
          this.orderingProductMaster = this.orderingProductMasterList[0];
          this.GetRxMasterByOrderingId(this.orderingProductMasterList[0].orderingProductID);
          this.changeTableRowColor(0);
        }
        this.showDiv = true;
        this.changeId = this.orderingProductMaster.orderingProductID;
        this.GetAllVendorPricings();
      }
      else {
        this.showDiv = false;
      }
    });
  }
  GetInventoryUOM() {

    this.Rxmasterservices.GetInventoryUOM().subscribe((result: any) => {
      this.inventoryuoms = result.data;

    });
  }
  GetAllVendorPricings() {
    this.masterService.GetAllVendorPricings(this.orderingProductMaster.orderingProductID).subscribe((result: any) => {
      this.vendorPricingList = result.data;
      if (this.vendorPricingList.length > 0) {
        this.vendorPricing = this.vendorPricingList[0];
        this.showPriceingDiv = true;
      }
      else {
        this.showPriceingDiv = false;
      }
    });
  }
  GetVendors() {
    this.commonService.GetVendorsDropDown().subscribe((result: any) => {
      this.vendorsDropDown = result.data;

    });
  }
  changeTableRowColor(index: any) {
    this.rowClicked = index;
  }
  GetDetails(printers) {

    this.orderingProductMaster = printers;
    this.changeId = this.orderingProductMaster.orderingProductID;
    this.GetAllVendorPricings();
    this.GetRxMasterByOrderingId(this.changeId);
    this.disableEdit();
  }
  GetRxMasterByOrderingId(orderingid) {
    this.masterService.GetRxMasterByOrderingId(orderingid).subscribe((result: any) => {

      if (result.data.length > 0) {
        this.IsUsedUOM = true;
      }
    });
  }
  enableEdit() {

    this.orderingProductMasterForm.enable();
    //if (this.IsUsedUOM == true) {
    //  this.orderingProductMasterForm.controls['Itemuom'].disable();
    //}
    this.IsEdit = true;
    if (this.orderingProductMaster != null) {
      this.changeId = this.orderingProductMaster.orderingProductID;

      if (this.IsUsedUOM == true) {
        this.orderingProductMasterForm.controls['Itemuom'].disable();
      }
    }
  }
  disableEdit() {

    this.orderingProductMasterForm.disable();
    this.IsEdit = false;
    if (this.changeId != null && this.orderingProductMasterList != null) {
      this.GetAllOrderingProductMasters();
    }
  }
  edit() {
    this.utilityServices.startLoader();
    //this.ValidateInput(this.f.OrderingProductID.value, 'OrderingProductNo', this.f.OrderingProductNo.value, 'OrderingProductNo', 1);
    //if (this.IsErrorOccurred) {
    //  this.utilityServices.stopLoader();
    //  return;
    //}
    if (this.orderingProductMasterForm.invalid) {
      this.orderingProductMasterForm.markAllAsTouched();
      this.utilityServices.stopLoader();
      //ShowMessage({ type: "error", message: "Please fill form properly" });
      return;
    }
    

    this.commonService.ValidateInput("OrderingProductMaster", "OrderingProductID", this.f.OrderingProductID.value, 'OrderingProductNo', this.f.OrderingProductNo.value).subscribe((res: any) => {
      if (res.isSuccess) {
        if (res.data == true) {
          this.IsErrorOccurred = true;
          ShowMessage({ type: "error", message: "Entered Ordering Product No '" + this.f.OrderingProductNo.value + "' already exists." });
          this.utilityServices.stopLoader();
        }
        else {
          if (this.f.Active.value == false) {
            this.masterService.IsOrderingProductNoInUse(this.f.OrderingProductID.value).subscribe((res: any) => {
              if (res.isSuccess) {
                if (res.data == true) {
                  this.IsErrorOccurred = true;
                  ShowMessage({ type: "error", message: "Entered Ordering Product No '" + this.f.OrderingProductNo.value + "' is already use in rx-master." });
                  this.utilityServices.stopLoader();
                }
                else {
                  this.IsErrorOccurred = false;
                  const mo: OrderingProductMaster = new OrderingProductMaster();
                  mo.orderingProductID = this.f.OrderingProductID.value;
                  mo.orderingProductNo = this.f.OrderingProductNo.value;
                  mo.orderingProductDescription = this.f.OrderingProductDescription.value;
                  mo.itemuom = this.f.Itemuom.value;
                  // mo.purchaseuom = this.f.Purchaseuom.value;
                  //s mo.purconversionfactor = this.f.Purconversionfactor.value;
                  mo.active = this.f.Active.value;

                  this.changeId = 0;
                  this.masterService.SaveOrderingProductMasterData(mo).subscribe((res: any) => {
                    if (res.isSuccess) {
                      this.changeId = res.data;
                      this.IsEdit = false;
                      this.addOrderingProductMasterForm.reset();
                      this.addOrderingProductMasterForm.controls['Active'].setValue(true);
                      this.GetAllOrderingProductMasters();
                      this.disableEdit();
                      this.utilityServices.stopLoader();
                      ShowMessage({ type: "success", message: res.message });
                    }
                    else {
                      this.utilityServices.stopLoader();
                      ShowMessage({
                        type: "error", message: res.message
                      });
                    }
                  }, (err) => {
                    this.utilityServices.stopLoader();
                    ShowMessage({ type: "error", message: "Something went wrong..." });
                  });
                  this.utilityServices.stopLoader();
                }
              }
            }, (err) => {
              this.utilityServices.stopLoader();
              ShowMessage({ type: "error", message: "Something went wrong..." });
            });
          }
          else {
            this.IsErrorOccurred = false;
            const mo: OrderingProductMaster = new OrderingProductMaster();
            mo.orderingProductID = this.f.OrderingProductID.value;
            mo.orderingProductNo = this.f.OrderingProductNo.value;
            mo.orderingProductDescription = this.f.OrderingProductDescription.value;
            mo.itemuom = this.f.Itemuom.value;
            // mo.purchaseuom = this.f.Purchaseuom.value;
            //s mo.purconversionfactor = this.f.Purconversionfactor.value;
            mo.active = this.f.Active.value;

            this.changeId = 0;
            this.masterService.SaveOrderingProductMasterData(mo).subscribe((res: any) => {
              if (res.isSuccess) {
                this.changeId = res.data;
                this.IsEdit = false;
                this.addOrderingProductMasterForm.reset();
                this.addOrderingProductMasterForm.controls['Active'].setValue(true);
                this.GetAllOrderingProductMasters();
                this.disableEdit();
                this.utilityServices.stopLoader();
                ShowMessage({ type: "success", message: res.message });
              }
              else {
                this.utilityServices.stopLoader();
                ShowMessage({
                  type: "error", message: res.message
                });
              }
            }, (err) => {
              this.utilityServices.stopLoader();
              ShowMessage({ type: "error", message: "Something went wrong..." });
            });
            this.utilityServices.stopLoader();
          }
        }
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });

    //const mo: OrderingProductMaster = new OrderingProductMaster();
    //mo.orderingProductID = this.f.OrderingProductID.value;
    //mo.orderingProductNo = this.f.OrderingProductNo.value;
    //mo.orderingProductDescription = this.f.OrderingProductDescription.value;
    //mo.itemuom = this.f.Itemuom.value;
    //// mo.purchaseuom = this.f.Purchaseuom.value;
    ////s mo.purconversionfactor = this.f.Purconversionfactor.value;
    //mo.active = this.f.Active.value;

    //this.changeId = 0;
    //this.masterService.SaveOrderingProductMasterData(mo).subscribe((res: any) => {
    //  if (res.isSuccess) {
    //    this.changeId = res.data;
    //    this.IsEdit = false;
    //    this.addOrderingProductMasterForm.reset();
    //    this.addOrderingProductMasterForm.controls['Active'].setValue(true);
    //    this.GetAllOrderingProductMasters();
    //    this.disableEdit();
    //    this.utilityServices.stopLoader();
    //    ShowMessage({ type: "success", message: res.message });
    //  }
    //  else {
    //    this.utilityServices.stopLoader();
    //    ShowMessage({
    //      type: "error", message: res.message
    //    });
    //  }
    //}, (err) => {
    //  this.utilityServices.stopLoader();
    //  ShowMessage({ type: "error", message: "Something went wrong..." });
    //});

  }
  add() {
    this.utilityServices.startLoader();

    //this.ValidateInput('0', 'OrderingProductNo', this.a.OrderingProductNo.value, 'OrderingProductNo', 2);
    //if (this.IsErrorOccurred) {
    //  this.utilityServices.stopLoader();
    //  return;
    //}
    if (this.addOrderingProductMasterForm.invalid) {
      this.addOrderingProductMasterForm.markAllAsTouched();
      this.utilityServices.stopLoader();
      //ShowMessage({ type: "error", message: "Please fill form properly" });
      return;
    }

    this.commonService.ValidateInput("OrderingProductMaster", "OrderingProductID", "0", 'OrderingProductNo', this.a.OrderingProductNo.value).subscribe((res: any) => {
      if (res.isSuccess) {
        if (res.data == true) {
          this.IsErrorOccurred = true;
          ShowMessage({ type: "error", message: "Entered Ordering Product No '" + this.a.OrderingProductNo.value + "' already exists." });
          this.utilityServices.stopLoader();
        }
        else {
          this.IsErrorOccurred = false;
          const mo: OrderingProductMaster = new OrderingProductMaster();
          mo.orderingProductID = 0;
          mo.orderingProductNo = this.a.OrderingProductNo.value;
          mo.orderingProductDescription = this.a.OrderingProductDescription.value;
          mo.itemuom = this.a.Itemuom.value;
          //mo.purchaseuom = this.a.Purchaseuom.value;
          //mo.purconversionfactor = this.a.Purconversionfactor.value;
          mo.active = this.a.Active.value;
          this.changeId = 0;
          this.masterService.SaveOrderingProductMasterData(mo).subscribe((res: any) => {
            if (res.isSuccess) {
              this.changeId = res.data;
              this.addOrderingProductMasterForm.reset();
              this.addOrderingProductMasterForm.controls['Active'].setValue(true);
              this.GetAllOrderingProductMasters();
              this.disableEdit();
              this.utilityServices.stopLoader();
              ShowMessage({ type: "success", message: res.message });
            }
            else {
              this.utilityServices.stopLoader();
              ShowMessage({
                type: "error", message: res.message
              });
            }
            $("#default-example-modal-lg-center").modal('hide');
          }, (err) => {
            this.utilityServices.stopLoader();
            ShowMessage({ type: "error", message: "Something went wrong..." });
          });
          this.utilityServices.stopLoader();
        }
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
    //const mo: OrderingProductMaster = new OrderingProductMaster();
    //mo.orderingProductID = 0;
    //mo.orderingProductNo = this.a.OrderingProductNo.value;
    //mo.orderingProductDescription = this.a.OrderingProductDescription.value;
    //mo.itemuom = this.a.Itemuom.value;
    ////mo.purchaseuom = this.a.Purchaseuom.value;
    ////mo.purconversionfactor = this.a.Purconversionfactor.value;
    //mo.active = this.a.Active.value;
    //this.changeId = 0;
    //this.masterService.SaveOrderingProductMasterData(mo).subscribe((res: any) => {
    //  if (res.isSuccess) {
    //    this.changeId = res.data;
    //    this.addOrderingProductMasterForm.reset();
    //    this.addOrderingProductMasterForm.controls['Active'].setValue(true);
    //    this.GetAllOrderingProductMasters();
    //    this.disableEdit();
    //    this.utilityServices.stopLoader();
    //    ShowMessage({ type: "success", message: res.message });
    //  }
    //  else {
    //    this.utilityServices.stopLoader();
    //    ShowMessage({
    //      type: "error", message: res.message
    //    });
    //  }
    //  $("#default-example-modal-lg-center").modal('hide');
    //}, (err) => {
    //  this.utilityServices.stopLoader();
    //  ShowMessage({ type: "error", message: "Something went wrong..." });
    //});

  }
  Remove(ID: number) {
    this.utilityServices.startLoader();
    this.masterService.RemoveOrderingProduct(ID.toString()).subscribe((res: any) => {
      if (res.isSuccess) {
        $("#SRX_OrderingProductMaster").DataTable().row(this.orderingProductMasterList.findIndex(o => o.orderingProductID == ID)).remove();
        this.GetAllOrderingProductMasters();
        this.utilityServices.stopLoader();
        ShowMessage({ type: "success", message: res.message });
      }
      else {
        this.utilityServices.stopLoader();
        ShowMessage({
          type: "error", message: res.message
        });
      }
    }, (err) => {
      this.utilityServices.stopLoader();
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
  }
  addVendor() {
    this.utilityServices.startLoader();
    if (this.vendorPricingForm.invalid) {
      this.vendorPricingForm.markAllAsTouched();
      this.utilityServices.stopLoader();
      //ShowMessage({ type: "error", message: "Please fill form properly" });
      return;
    }

    const mo: OrderingProductVendorPricing = new OrderingProductVendorPricing();
    mo.ovpid = 0;
    mo.orderingProductID = this.orderingProductMaster.orderingProductID;
    mo.vendorID = this.v.VendorID.value;
    mo.price = this.v.Price.value;
    mo.selected = (this.v.Selected.value == null ? false : this.v.Selected.value);
    mo.active = this.v.Active.value;

    if (this.vendorPricingList.length > 0) {
      if (this.vendorPricingList.filter(x => x.vendorID == mo.vendorID).length > 0) {
        this.utilityServices.stopLoader();
        ShowMessage({ type: "Error", message: "Vendor Already exists in list" });
        return;
      }
    }

    this.masterService.SaveVendorPricingData(mo).subscribe((res: any) => {
      if (res.isSuccess) {
        this.vendorPricingForm.reset();
        this.vendorPricingForm.controls['Active'].setValue(1);
        this.GetAllVendorPricings();
        this.disableEdit();
        this.utilityServices.stopLoader();
        ShowMessage({ type: "success", message: res.message });
      }
      else {
        this.utilityServices.stopLoader();
        ShowMessage({
          type: "error", message: res.message
        });
      }
      $("#vendor-pricing-popup-model").modal('hide');
    }, (err) => {
      this.utilityServices.stopLoader();
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });

  }

  deletevendorpricing(ID: number) {
    // this.vendorPricingList.splice(index, 1);

    this.utilityServices.startLoader();
    this.masterService.RemoveVendorPricing(ID).subscribe((res: any) => {
      if (res.isSuccess) {
        this.GetAllVendorPricings();
        this.utilityServices.stopLoader();
        ShowMessage({ type: "success", message: res.message });
      }
      else {
        this.utilityServices.stopLoader();
        ShowMessage({
          type: "error", message: res.message
        });
      }
    }, (err) => {
      this.utilityServices.stopLoader();
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
  }

  VendorEditShow(vendorPriceing) {
    this.vendorPricing = vendorPriceing;
    $("#vendor-pricing-popup-model").modal('show');
  }
  editVendor() {
    this.utilityServices.startLoader();
    if (this.editVendorPricingForm.invalid) {
      this.editVendorPricingForm.markAllAsTouched();
      this.utilityServices.stopLoader();
      //ShowMessage({ type: "error", message: "Please fill form properly" });
      return;
    }

    const mo: OrderingProductVendorPricing = new OrderingProductVendorPricing();
    mo.ovpid = this.e.OVPID.value;
    mo.orderingProductID = this.orderingProductMaster.orderingProductID;
    mo.vendorID = this.e.VendorID.value;
    mo.price = this.e.Price.value;
    mo.selected = this.e.Selected.value;
    mo.active = this.e.Active.value;
    this.masterService.SaveVendorPricingData(mo).subscribe((res: any) => {
      if (res.isSuccess) {
        this.editVendorPricingForm.reset();
        this.GetAllVendorPricings();
        this.disableEdit();
        this.utilityServices.stopLoader();
        ShowMessage({ type: "success", message: res.message });
      }
      else {
        this.utilityServices.stopLoader();
        ShowMessage({
          type: "error", message: res.message
        });
      }
      $("#edit-vendor-pricing-popup-model").modal('hide');
    }, (err) => {
      this.utilityServices.stopLoader();
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
  }
  ConfirmDelete(ID: number) {
    var x = confirm("Are you sure you want to delete ordering product and the associated vendor pricing data?");
    if (x)
      this.Remove(ID);
    else
      return false;
  }
  ValidateInput(Id?: string, FieldName?: string, Value?: string, ControlName?: string, fn?: number) {
    this.commonService.ValidateInput("OrderingProductMaster", "OrderingProductID", Id, FieldName, Value).subscribe((res: any) => {
      if (res.isSuccess) {
        if (res.data == true) {
          this.IsErrorOccurred = true;
          if (FieldName === 'OrderingProductNo') {
            ShowMessage({ type: "error", message: "Entered Ordering Product No '" + Value + "' already exists." });
          }
          //if (fn == 1 && ControlName == "OrderingProductNo") {
          //  this.orderingProductMasterForm.patchValue({
          //    OrderingProductNo: ''
          //  });
          //}
          //else if (fn == 2 && ControlName == "OrderingProductNo") {
          //  this.addOrderingProductMasterForm.patchValue({
          //    OrderingProductNo: ''
          //  });
          //}
        }
        else {
          this.IsErrorOccurred = false;
        }
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
  }
}
