import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { User } from '../_models/user';
import { AuthenticationService } from '../_services/authentication.service';
import { first } from 'rxjs/operators';


declare function ShowAlert(config): any;
declare function ShowMessage(config): any;
@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css']
})

export class MainLayoutComponent {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: User;

  public initials: string;
  public showInitials = false;
  public circleColor: string;
  
  currentYear: number = new Date().getFullYear();

  constructor(private router: Router,private authenticationService: AuthenticationService) {
    if (this.authenticationService.currentUserValue) {
      this.currentUserSubject = new BehaviorSubject<User>(this.authenticationService.currentUserValue);
    
      this.currentUser = this.currentUserSubject.value;
     
      if (this.currentUser.profilePhotoName === null || this.currentUser.profilePhotoName === "") {
        this.showInitials = true;
        this.CretePictureFromInitials();
        this.circleColor = '#a38cc6'; 
      }
      if (this.authenticationService.currentUserValue.isWarning == true) {
        ShowAlert({ type: "warning", message: this.authenticationService.currentUserValue.notificationMsg });
      }
    }
  }

  logoutUser() {
     
    this.authenticationService.LogoutHistory(this.currentUserSubject.value.userName).pipe(first())
      .subscribe(
        data => {
          this.authenticationService.logout();
          this.router.navigate(['/']);
        },
        error => {
          ShowMessage({ type: "error", message: error });
        });
  
  }
  onClickWarning() {
    ShowMessage({ type: "warning", message: "This page is under construction" });
  }
  CretePictureFromInitials() {
    let initials = "";
    initials = this.currentUser.fName.charAt(0).toUpperCase() + this.currentUser.lName.charAt(0).toUpperCase();
    this.initials = initials;
  }
}
