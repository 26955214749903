import { Role } from "./role";
import { permissionMaster } from "./permissions";
import { menu } from "./menu";
import { ProviderMaster } from "./clinicalrolemst";
export class User {
  UserId: number;
  UserSRXID?: string;
  UserName?: string;
  Password: string;
  UserType?: number;
  Title?: string;
  fName?: string;
  lName?: string;
  MI?: string;
  provider?: number;
  UserDef1?: string;
  UserDef2?: string;
  UserDef3?: string;
  UserDef4?: string;
  UserDef5?: string;
  Notes?: string;
  CreatedDate?: Date;
  NPI?: string;
  fullName?: string;
  EMailId?: string;
  UserValue?: number;
  ClinicalRoleId?: number;
  access_token?: string;
  isWarning: boolean;
  notificationMsg?: string;
  lstPermission?: permissionMaster[];
  lstMenu?: menu[]
  userName: string;
  userId: number;
  isActive: boolean;
  role: number;
  UserTypeName?: string;
  providerList: ProviderMaster[];
  clientId?: string;
  sessionTimeOut?: string;
  profilePhotoName?: string;
  isShowReason: boolean;
}
export class LoginReasonModel {
  Reason: number;
  ReasonText?: string;
}
export class LoginReasonMaster {
  rid: number;
  reason: string;
  isShowTextBox: boolean;
  isActive: boolean;
}
