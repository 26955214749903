import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User, LoginReasonMaster, LoginReasonModel } from '../_models/user';
import { SidebarMenuDirective } from './sidebar-menu.directive';

import { AuthenticationService } from '../_services/authentication.service';
import { permissionMaster } from '../_models';
import { CommonService } from '../_services/common.service';
import { error } from 'protractor';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { debug } from 'console';
import { UserService } from '../_services';

declare function ResetNavbar(): any;
declare function ShowMessage(config): any;
declare function InitBasicDataTable(selector): any;
declare var $: any;
declare function AddRemoveClass(selector): any;
declare function SetClassForMenu(event): any;
declare function SetClassForSubMenu(event, parentid, menuid): any;

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.css']
})
export class SidebarMenuComponent implements OnInit {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: User;
  public menuIteams: permissionMaster[];
  public lstReason: LoginReasonMaster[];
  addLoginReason: FormGroup;
  IsOtherReason: boolean = false;
  menuList: any;
  submenulist: any;
  currenturl: any;
  parentid: number;
  mainloopbreak: boolean = false;
  currentParentid: number;
  public showInitials = false;
  public initials: string;
  public circleColor: string;

  constructor(
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private formBuilder: FormBuilder, private router: Router
  ) {
    if (this.authenticationService.currentUserValue) {

      this.GetMenu();
      this.currentUserSubject = new BehaviorSubject<User>(this.authenticationService.currentUserValue);
      this.currentUser = this.currentUserSubject.value;
      if (this.currentUser.profilePhotoName === null || this.currentUser.profilePhotoName === "") {
        this.showInitials = true;
        this.CretePictureFromInitials();
        this.circleColor = '#a38cc6';
      }
    }
  }

  ngOnInit() {

    this.currenturl = this.router.url;
    this.GetCurrentParent();
    ResetNavbar();
    if (JSON.parse(localStorage.getItem('isShowReason'))) {
      this.GetLoginReasonMaster();
      $("#default-reason-modal-lg-center").modal('show');
    }
    this.addLoginReason = this.formBuilder.group({
      Reason: [null, Validators.required],
      ReasonText: [null]
    });
  }
  get r() {
    return this.addLoginReason.controls;
  }
  AddRemoveClassReason() {

    AddRemoveClass("#addReason");
  }
  isExpanded = false;

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  GetMenu() {
    this.commonService.GetMenu().subscribe((res: any) => {
      // this.menuList = res.data.filter(x => x.category == 1);      
      this.menuList = res.data.filter(x => x.permissionTypeID == 1);
      // this.parentid = 0;
      //for (var i = 0; i < this.menuList.length; i++) {
      //  if (this.mainloopbreak == false) {
      //    this.submenulist = this.menuList[i].categories;
      //    for (var j = 0; j < this.submenulist.length; j++) {
      //      if (this.submenulist[j].path == this.currenturl) {
      //        this.parentid = this.submenulist[j].parentId;
      //        this.mainloopbreak = true;
      //        $("#" + this.parentid).addClass("current");
      //        break;
      //      }
      //    }
      //  }
      //  else {
      //    break;
      //  }
      //}


    });
  }
  //getParentID(pid) {
  //  this.parentid = pid;
  //}
  SetClassForMenu(event) {        
    SetClassForMenu(event);

  }
  SetClassForSubMenu(event, parentid, menuid) {

    SetClassForSubMenu(event, parentid, menuid);

  }

  GetLoginReasonMaster() {
    this.commonService.GetLoginReasonMaster().subscribe((lstReasonMaster: any) => {
      this.lstReason = lstReasonMaster.data;
    });
  }
  addReason() {    
    if (this.addLoginReason.invalid) {
      this.addLoginReason.markAllAsTouched();
      //ShowMessage({ type: "error", message: "Please fill form properly" });
      return;
    }
    if (this.IsOtherReason == true && (this.r.ReasonText.value == null || this.r.ReasonText.value == undefined || this.r.ReasonText.value == '')) {
      ShowMessage({ type: "error", message: "Please add other reason" });
      return;
    }
    if (this.r.Reason.value == 'null') {
      ShowMessage({ type: "error", message: "Please select reason" });
      return;
    }
    const lr: LoginReasonModel = new LoginReasonModel();
    lr.Reason = this.r.Reason.value;
    lr.ReasonText = this.r.ReasonText.value;
    this.authenticationService.SaveLoginReason(lr).subscribe((res: any) => {
      if (res.isSuccess) {

        localStorage.removeItem('isShowReason');
        localStorage.setItem('isShowReason', JSON.stringify(false));
        $("#default-reason-modal-lg-center").modal('hide');
        ShowMessage({ type: "success", message: res.message });
      }
      else {
        ShowMessage({
          type: "error", message: res.message
        });
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });

  }
  ChangeIsOtherReason() {

    if (this.r.Reason.value == "null") {
      this.IsOtherReason = false;
    }
    else {
      this.IsOtherReason = this.lstReason.find(x => x.rid == this.r.Reason.value).isShowTextBox;
    }
    //this.IsOtherReason = isShow;
  }
  GetCurrentParent() {
    this.authenticationService.GetCurrentParent(this.currenturl).subscribe((res: any) => {
      if (res.isSuccess) {
        this.currentParentid = res.data;
      }
    });
  }
  CretePictureFromInitials() {
    let initials = "";
    initials = this.currentUser.fName.charAt(0).toUpperCase() + this.currentUser.lName.charAt(0).toUpperCase();
    this.initials = initials;
  }
}
