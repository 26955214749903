import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RxTypeMaster } from '../../_models';
import { CommonService } from '../../_services/common.service';
import { MastersService } from '../../_services/masters.service';
import { UtilityServices } from '../../_helpers';
import { WhiteSpaceValidator } from '../../_helpers/_validations/must-match';

declare function ShowMessage(config): any;
declare function InitBasicDataTable(selector, title): any;
declare var $: any;
declare function AddRemoveClass(selector): any;

@Component({
  selector: 'app-rx-type',
  templateUrl: './rx-type.component.html',
  styleUrls: ['./rx-type.component.css']
})
export class RxTypeComponent implements OnInit {
  private rxTypeList: RxTypeMaster[];
  private rxType: RxTypeMaster;
  IsEdit: boolean = false;
  showDiv: boolean = false;
  IsErrorOccurredRxTypeId = false;
  IsErrorOccurredRxTypeName = false;
  changeId: number;
  rowClicked: any;
  rxTypeForm: FormGroup;
  addRxTypeForm: FormGroup;
  constructor(private formBuilder: FormBuilder, private masterService: MastersService, private commonService: CommonService, private utilityServices: UtilityServices) {
    this.GetAllRxTypes();
  }

  ngOnInit() {
    //this.GetAllRxTypes();
    this.InitDataTable();
    this.rxTypeForm = this.formBuilder.group({
      Id: [null],
      RxTypeID: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      RxTypeName: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      Vaccine_Y_N: [null, Validators.required],
      SortOrder: [null, [Validators.required, Validators.pattern("^[0-9]{1,2}$")]],
      Active: [null, Validators.required]
    });
    this.disableEdit();
    this.addRxTypeForm = this.formBuilder.group({
      RxTypeID: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      RxTypeName: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      Vaccine_Y_N: ["N", Validators.required],
      SortOrder: [1, [Validators.required, Validators.pattern("^[0-9]{1,2}$")]],
      Active: [1, Validators.required]
    });
  }
  InitDataTable() {
    InitBasicDataTable("#SRX_RxTypeMaster", "SRX_RxTypeMaster");
  }
  AddRemoveClass() {
    AddRemoveClass("#addnewtab");
  }
  get f() {
    return this.rxTypeForm.controls;
  }
  get a() {
    return this.addRxTypeForm.controls;
  }
  GetAllRxTypes() {

    this.masterService.GetAllRxTypes().subscribe((res: any) => {

      $('#SRX_RxTypeMaster').dataTable().fnClearTable();
      $('#SRX_RxTypeMaster').dataTable().fnDestroy();

      this.rxTypeList = [];
      this.rxTypeList = res.data;

      //$("#SRX_RxTypeMaster").DataTable().data(this.rxTypeList);
      //$("#SRX_RxTypeMaster").DataTable().rows.add(this.rxTypeList).draw();
      if (this.rxTypeList.length > 0) {
        if (this.changeId >= 1 && this.rxTypeList.find(o => o.id == this.changeId) != null) {
          this.rxType = this.rxTypeList.find(o => o.id == this.changeId);
          this.changeTableRowColor(this.rxTypeList.findIndex(o => o.id == this.changeId));
          //$("#SRX_RxTypeMaster").DataTable().row(this.rxTypeList.findIndex(o => o.id == this.changeId)).scrollTo();
          //$("#SRX_RxTypeMaster").DataTable().row(this.rxTypeList.findIndex(o => o.id == this.changeId)).push();
        }
        else {
          this.rxType = this.rxTypeList[0];
          this.changeTableRowColor(0);
        }
        this.showDiv = true;
        this.changeId = this.rxType.id;
      }
      else {
        this.showDiv = false;
      }
    });
  }
  changeTableRowColor(index: any) {
    this.rowClicked = index;
  }
  GetDetails(rxtypes) {
    this.rxType = rxtypes;
    this.changeId = this.rxType.id;
    this.disableEdit();
  }
  enableEdit() {
    this.rxTypeForm.enable();
    this.IsEdit = true;
    if (this.rxType != null) {
      this.changeId = this.rxType.id;
    }
  }
  disableEdit() {
    this.rxTypeForm.disable();
    this.IsEdit = false;
    if (this.changeId != null && this.rxTypeList != null) {
      this.GetAllRxTypes();
    }
  }
  ResetAddForm() {
    this.addRxTypeForm.reset();
    this.addRxTypeForm.controls['Vaccine_Y_N'].patchValue("N");
    this.addRxTypeForm.controls['Active'].patchValue(1);
    this.addRxTypeForm.controls['SortOrder'].patchValue(1);
  }
  edit() {
    this.utilityServices.startLoader();
    //this.ValidateInput(this.f.Id.value, 'RxTypeID', this.f.RxTypeID.value, 'RxTypeID', 2);
    //this.ValidateInput(this.f.Id.value, 'RxTypeName', this.f.RxTypeName.value, 'RxTypeName', 2);

    //if (this.IsErrorOccurredRxTypeId || this.IsErrorOccurredRxTypeName) {
    //  this.utilityServices.stopLoader();
    //  return;
    //}

    if (this.rxTypeForm.invalid) {
      this.rxTypeForm.markAllAsTouched();
      this.utilityServices.stopLoader();
      //ShowMessage({ type: "error", message: "Please fill form properly" });
      return;
    }

    this.commonService.ValidateInput("RxType", "ID", this.f.Id.value, 'RxTypeID', this.f.RxTypeID.value).subscribe((res: any) => {
      if (res.isSuccess) {
        if (res.data == true) {
          this.IsErrorOccurredRxTypeId = true;
          ShowMessage({ type: "error", message: "RxType ID '" + this.f.RxTypeID.value + "' already exists." });
          this.utilityServices.stopLoader();
        }
        else {
          //here we checking RxTypeName
          this.IsErrorOccurredRxTypeId = false;
          this.commonService.ValidateInput("RxType", "ID", this.f.Id.value, 'RxTypeName', this.f.RxTypeName.value).subscribe((res: any) => {
            if (res.isSuccess) {
              if (res.data == true) {
                ShowMessage({ type: "error", message: "RxType Name '" + this.f.RxTypeName.value + "' already exists." });
                this.utilityServices.stopLoader();
              }
              else {
                //here we are save the data
                this.IsErrorOccurredRxTypeName = false;
                const mo: RxTypeMaster = new RxTypeMaster();
                mo.id = this.f.Id.value;
                mo.rxTypeID = this.f.RxTypeID.value;
                mo.rxTypeName = this.f.RxTypeName.value;
                mo.vaccine_Y_N = this.f.Vaccine_Y_N.value;
                mo.sortOrder = this.f.SortOrder.value;
                mo.active = this.f.Active.value;

                this.changeId = 0;
                this.masterService.SaveRxTypeData(mo).subscribe((res: any) => {
                  if (res.isSuccess) {
                    this.changeId = res.data;
                    this.IsEdit = false;
                    this.addRxTypeForm.reset();
                    this.addRxTypeForm.controls['Active'].setValue(1);
                    this.addRxTypeForm.controls['Vaccine_Y_N'].setValue("N");
                    this.GetAllRxTypes();
                    this.disableEdit();
                    this.utilityServices.stopLoader();
                    ShowMessage({ type: "success", message: res.message });
                  }
                  else {
                    this.utilityServices.stopLoader();
                    ShowMessage({
                      type: "error", message: res.message
                    });
                  }
                }, (err) => {
                  this.utilityServices.stopLoader();
                  ShowMessage({ type: "error", message: "Something went wrong..." });
                });
                this.utilityServices.stopLoader();
              }
            }
          }, (err) => {
            ShowMessage({ type: "error", message: "Something went wrong..." });
          });
        }
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });

    //const mo: RxTypeMaster = new RxTypeMaster();
    //mo.id = this.f.Id.value;
    //mo.rxTypeID = this.f.RxTypeID.value;
    //mo.rxTypeName = this.f.RxTypeName.value;
    //mo.vaccine_Y_N = this.f.Vaccine_Y_N.value;
    //mo.sortOrder = this.f.SortOrder.value;
    //mo.active = this.f.Active.value;

    //this.changeId = 0;
    //this.masterService.SaveRxTypeData(mo).subscribe((res: any) => {
    //  if (res.isSuccess) {
    //    this.changeId = res.data;
    //    this.IsEdit = false;
    //    this.addRxTypeForm.reset();
    //    this.addRxTypeForm.controls['Active'].setValue(1);
    //    this.addRxTypeForm.controls['Vaccine_Y_N'].setValue("N");
    //    this.GetAllRxTypes();
    //    this.disableEdit();
    //    this.utilityServices.stopLoader();
    //    ShowMessage({ type: "success", message: res.message });
    //  }
    //  else {
    //    this.utilityServices.stopLoader();
    //    ShowMessage({
    //      type: "error", message: res.message
    //    });
    //  }
    //}, (err) => {
    //  this.utilityServices.stopLoader();
    //  ShowMessage({ type: "error", message: "Something went wrong..." });
    //});

  }
  add() {
    this.utilityServices.startLoader();
    //this.ValidateInput('0', 'RxTypeID', this.a.RxTypeID.value, 'RxTypeID', 1);
    //this.ValidateInput('0', 'RxTypeName', this.a.RxTypeName.value, 'RxTypeName', 1);

    //if (this.IsErrorOccurredRxTypeId || this.IsErrorOccurredRxTypeName) {
    //  this.utilityServices.stopLoader();
    //  return;
    //}
    if (this.addRxTypeForm.invalid) {
      this.addRxTypeForm.markAllAsTouched();
      this.utilityServices.stopLoader();
      //ShowMessage({ type: "error", message: "Please fill form properly" });
      return;
    }

    //first checking for RxTypeID
    this.commonService.ValidateInput("RxType", "ID", "0", 'RxTypeID', this.a.RxTypeID.value).subscribe((res: any) => {
      if (res.isSuccess) {
        if (res.data == true) {
          this.IsErrorOccurredRxTypeId = true;
          ShowMessage({ type: "error", message: "RxType ID '" + this.a.RxTypeID.value + "' already exists." });
          this.utilityServices.stopLoader();
        }
        else {
          //here we checking RxTypeName
          this.IsErrorOccurredRxTypeId = false;
          this.commonService.ValidateInput("RxType", "ID", "0", 'RxTypeName', this.a.RxTypeName.value).subscribe((res: any) => {
            if (res.isSuccess) {
              if (res.data == true) {
                ShowMessage({ type: "error", message: "RxType Name '" + this.a.RxTypeName.value + "' already exists." });
                this.utilityServices.stopLoader();
              }
              else {
                //here we are save the data
                this.IsErrorOccurredRxTypeName = false;
                const mo: RxTypeMaster = new RxTypeMaster();
                mo.id = 0;
                mo.rxTypeID = this.a.RxTypeID.value;
                mo.rxTypeName = this.a.RxTypeName.value;
                mo.vaccine_Y_N = this.a.Vaccine_Y_N.value;
                mo.sortOrder = this.a.SortOrder.value;
                mo.active = this.a.Active.value;


                this.changeId = 0;
                this.masterService.SaveRxTypeData(mo).subscribe((res: any) => {
                  if (res.isSuccess) {
                    this.changeId = res.data;
                    this.addRxTypeForm.reset();
                    this.addRxTypeForm.controls['Active'].setValue(1);
                    this.addRxTypeForm.controls['Vaccine_Y_N'].setValue("N");
                    this.GetAllRxTypes();
                    this.disableEdit();
                    $("#default-example-modal-lg-center").modal('hide');
                    this.utilityServices.stopLoader();
                    ShowMessage({ type: "success", message: res.message });
                  }
                  else {
                    this.utilityServices.stopLoader();
                    ShowMessage({
                      type: "error", message: res.message
                    });
                  }
                }, (err) => {
                  this.utilityServices.stopLoader();
                  ShowMessage({ type: "error", message: "Something went wrong..." });
                });
                this.utilityServices.stopLoader();
              }
            }
          }, (err) => {
            ShowMessage({ type: "error", message: "Something went wrong..." });
          });
        }
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });

    //const mo: RxTypeMaster = new RxTypeMaster();
    //mo.id = 0;
    //mo.rxTypeID = this.a.RxTypeID.value;
    //mo.rxTypeName = this.a.RxTypeName.value;
    //mo.vaccine_Y_N = this.a.Vaccine_Y_N.value;
    //mo.sortOrder = this.a.SortOrder.value;
    //mo.active = this.a.Active.value;


    //this.changeId = 0;
    //this.masterService.SaveRxTypeData(mo).subscribe((res: any) => {
    //  if (res.isSuccess) {
    //    this.changeId = res.data;
    //    this.addRxTypeForm.reset();
    //    this.addRxTypeForm.controls['Active'].setValue(1);
    //    this.addRxTypeForm.controls['Vaccine_Y_N'].setValue("N");
    //    this.GetAllRxTypes();
    //    this.disableEdit();
    //    $("#default-example-modal-lg-center").modal('hide');
    //    this.utilityServices.stopLoader();
    //    ShowMessage({ type: "success", message: res.message });
    //  }
    //  else {
    //    this.utilityServices.stopLoader();
    //    ShowMessage({
    //      type: "error", message: res.message
    //    });
    //  }
    //}, (err) => {
    //  this.utilityServices.stopLoader();
    //  ShowMessage({ type: "error", message: "Something went wrong..." });
    //});

  }

  Remove(ID: number) {
    this.utilityServices.startLoader();
    this.masterService.RemoveRxType(ID.toString()).subscribe((res: any) => {
      if (res.isSuccess) {
        $("#SRX_RxTypeMaster").DataTable().row(this.rxTypeList.findIndex(o => o.id == ID)).remove();
        this.GetAllRxTypes();
        this.utilityServices.stopLoader();
        ShowMessage({ type: "success", message: res.message });
      }
      else {
        this.utilityServices.stopLoader();
        ShowMessage({
          type: "error", message: res.message
        });
      }
    }, (err) => {
      this.utilityServices.stopLoader();
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
  }
  ConfirmDelete(ID: number) {
    var x = confirm("Are you sure want to delete this record?");
    if (x)
      this.Remove(ID);
    else
      return false;
  }
  onlyNumbers(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 47 && k < 59));
  }
  ValidateInput(Id?: string, FieldName?: string, Value?: string, ControlName?: string, fn?: number) {
    this.commonService.ValidateInput("RxType", "ID", Id, FieldName, Value).subscribe((res: any) => {
      if (res.isSuccess) {
        if (res.data == true) {
          if (ControlName == "RxTypeID") {
            this.IsErrorOccurredRxTypeId = true;
            ShowMessage({ type: "error", message: "RxType ID '" + Value + "' already exists." });
          }
          if (ControlName == "RxTypeName") {
            this.IsErrorOccurredRxTypeName = true;
            ShowMessage({ type: "error", message: "RxType Name '" + Value + "' already exists." });
          }

          //if (fn == 1 && ControlName == "RxTypeID") {
          //  this.rxTypeForm.patchValue({
          //    RxTypeID: ''
          //  });
          //}
          //else if (fn == 2 && ControlName == "RxTypeID") {
          //  this.addRxTypeForm.patchValue({
          //    RxTypeID: ''
          //  });
          //}
          //else if (fn == 1 && ControlName == "RxTypeName") {
          //  this.rxTypeForm.patchValue({
          //    RxTypeName: ''
          //  });
          //}
          //else if (fn == 2 && ControlName == "RxTypeName") {
          //  this.addRxTypeForm.patchValue({
          //    RxTypeName: ''
          //  });
          //}
        }
        else {
          if (ControlName == "RxTypeID") {
            this.IsErrorOccurredRxTypeId = false;
          }
          if (ControlName == "RxTypeName") {
            this.IsErrorOccurredRxTypeName = false;
          }
        }
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
  }
}
