import { Injectable } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Injectable({ providedIn: 'root' })
export class UtilityServices {
  @BlockUI() blockUI: NgBlockUI;

  constructor() { }

  startLoader() {
    this.blockUI.start();
  }

  stopLoader() {
    this.blockUI.stop();
  }
}
