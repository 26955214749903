import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UnitMaster } from '../../_models';
import { CommonService } from '../../_services/common.service';
import { MastersService } from '../../_services/masters.service';
import { UtilityServices } from '../../_helpers';
import { WhiteSpaceValidator } from '../../_helpers/_validations/must-match';

declare function ShowMessage(config): any;
declare function InitBasicDataTable(selector, title): any;
declare var $: any;
declare function AddRemoveClass(selector): any;
declare function CharactersOnly(event): any;

@Component({
  selector: 'app-unit',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.css']
})
export class UnitComponent implements OnInit {
  private unitsList: UnitMaster[];
  private unit: UnitMaster;
  IsEdit: boolean = false;
  IsErrorOccurred = false;
  changeId: number;
  rowClicked: any;
  unitForm: FormGroup;
  addUnitForm: FormGroup;
  showDiv: boolean = false;
  constructor(private formBuilder: FormBuilder, private masterService: MastersService, private commonService: CommonService, private utilityServices: UtilityServices) {
    this.GetAllUnits();
  }

  ngOnInit() {
    this.unitForm = this.formBuilder.group({
      UnitID: [null],
      Unitname: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      SortOrder: [null, [Validators.required, Validators.pattern("^[0-9]*$")]],
      Active: [null, Validators.required]
    });
    this.disableEdit();
    this.addUnitForm = this.formBuilder.group({
      UnitID: [null],
      Unitname: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      SortOrder: [1, [Validators.required, Validators.pattern("^[0-9]*$")]],
      Active: [1, Validators.required]
    });
  }

  CharactersOnly(event): boolean {

    if (CharactersOnly(event) == true) {
      return true;
    }
    else {
      return false;
    }
  }

  InitDataTable() {
    InitBasicDataTable("#SRX_UnitMaster", "SRX_UnitMaster");
  }
  AddRemoveClass() {
    AddRemoveClass("#addnewtab");
  }
  get f() {
    return this.unitForm.controls;
  }
  get a() {
    return this.addUnitForm.controls;
  }
  GetAllUnits() {

    this.masterService.GetAllUnits().subscribe((res: any) => {
      $('#SRX_UnitMaster').dataTable().fnClearTable();
      $('#SRX_UnitMaster').dataTable().fnDestroy();
      this.unitsList = [];
      this.unitsList = res.data;
      if (this.unitsList.length > 0) {
        if (this.changeId >= 1 && this.unitsList.find(o => o.unitID == this.changeId) != null) {
          this.unit = this.unitsList.find(o => o.unitID == this.changeId);
          this.changeTableRowColor(this.unitsList.findIndex(o => o.unitID == this.changeId));
          //$("#SRX_UnitMaster").DataTable().row(this.unitsList.findIndex(o => o.unitID == this.changeId)).scrollTo();
        }
        else {
          this.unit = this.unitsList[0];
          this.changeTableRowColor(0);
        }
        this.showDiv = true;
        this.changeId = this.unit.unitID;
      }
      else {
        this.showDiv = false;
      }
    });
  }
  RemoveUnit(ID: number) {

    this.masterService.RemoveUnit(ID.toString()).subscribe((res: any) => {
      if (res.isSuccess) {
        $("#SRX_UnitMaster").DataTable().row(this.unitsList.findIndex(o => o.unitID == ID)).remove();
        this.GetAllUnits();
        ShowMessage({ type: "success", message: res.message });
      }
      else {
        ShowMessage({
          type: "error", message: res.message
        });
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
  }

  ConfirmDelete(ID: number) {
    var x = confirm("Are you sure want to delete this record?");
    if (x)
      this.RemoveUnit(ID);
    else
      return false;
  }
  changeTableRowColor(index: any) {
    this.rowClicked = index;
  }
  GetDetails(units) {
    this.unit = units;
    this.changeId = this.unit.unitID;
    this.disableEdit();
  }
  enableEdit() {
    this.unitForm.enable();
    this.IsEdit = true;
    if (this.unit != null) {
      this.changeId = this.unit.unitID;
    }
  }
  disableEdit() {
    this.unitForm.disable();
    this.IsEdit = false;
    if (this.changeId != null && this.unitsList != null) {
      this.GetAllUnits();
    }
  }
  ResetAddForm() {
    this.addUnitForm.reset();
    this.addUnitForm.controls['SortOrder'].patchValue(1);
    this.addUnitForm.controls['Active'].patchValue(1);
  }
  edit() {
    this.utilityServices.startLoader();

    //this.ValidateInput(this.f.UnitID.value, 'Unitname', this.f.Unitname.value, 'Unitname', 1);
    //if (this.IsErrorOccurred) {
    //  this.utilityServices.stopLoader();
    //  return;
    //}
    if (this.unitForm.invalid) {
      this.unitForm.markAllAsTouched();
      this.utilityServices.stopLoader();
      //ShowMessage({ type: "error", message: "Please fill form properly" });
      return;
    }
    this.commonService.ValidateInput("UnitMaster", "UnitID", this.f.UnitID.value, 'Unitname', this.f.Unitname.value).subscribe((res: any) => {
      if (res.isSuccess) {
        if (res.data == true) {
          this.IsErrorOccurred = true;
          ShowMessage({ type: "error", message: "Unit Name '" + this.f.Unitname.value + "' already exists." });
          this.utilityServices.stopLoader();
        }
        else {
          this.IsErrorOccurred = false;
          const mo: UnitMaster = new UnitMaster();
          mo.unitID = this.f.UnitID.value;
          mo.unitname = this.f.Unitname.value;
          mo.sortOrder = this.f.SortOrder.value;
          mo.active = this.f.Active.value;

          this.changeId = 0;
          this.masterService.SaveUnitData(mo).subscribe((res: any) => {
            if (res.isSuccess) {
              this.changeId = res.data;
              this.IsEdit = false;
              this.addUnitForm.reset();
              this.addUnitForm.controls['Active'].setValue(1);
              this.GetAllUnits();
              this.disableEdit();
              this.utilityServices.stopLoader();
              ShowMessage({ type: "success", message: res.message });
            }
            else {
              this.utilityServices.stopLoader();
              ShowMessage({
                type: "error", message: res.message
              });
            }
          }, (err) => {
            this.utilityServices.stopLoader();
            ShowMessage({ type: "error", message: "Something went wrong..." });
          });
          this.utilityServices.stopLoader();
        }
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });

    //const mo: UnitMaster = new UnitMaster();
    //mo.unitID = this.f.UnitID.value;
    //mo.unitname = this.f.Unitname.value;
    //mo.sortOrder = this.f.SortOrder.value;
    //mo.active = this.f.Active.value;

    //this.changeId = 0;
    //this.masterService.SaveUnitData(mo).subscribe((res: any) => {
    //  if (res.isSuccess) {
    //    this.changeId = res.data;
    //    this.IsEdit = false;
    //    this.addUnitForm.reset();
    //    this.addUnitForm.controls['Active'].setValue(1);
    //    this.GetAllUnits();
    //    this.disableEdit();
    //    this.utilityServices.stopLoader();
    //    ShowMessage({ type: "success", message: res.message });
    //  }
    //  else {
    //    this.utilityServices.stopLoader();
    //    ShowMessage({
    //      type: "error", message: res.message
    //    });
    //  }
    //}, (err) => {
    //  this.utilityServices.stopLoader();
    //  ShowMessage({ type: "error", message: "Something went wrong..." });
    //});

  }
  add() {    
    this.utilityServices.startLoader();
    //this.ValidateInput('0', 'Unitname', this.a.Unitname.value, 'Unitname', 2);
    //if (this.IsErrorOccurred) {
    //  this.utilityServices.stopLoader();
    //  return;
    //}
    if (this.addUnitForm.invalid) {
      this.addUnitForm.markAllAsTouched();
      this.utilityServices.stopLoader();
      //ShowMessage({ type: "error", message: "Please fill form properly" });
      return;
    }
    this.commonService.ValidateInput("UnitMaster", "UnitID", "0", 'Unitname', this.a.Unitname.value).subscribe((res: any) => {
      if (res.isSuccess) {
        if (res.data == true) {
          this.IsErrorOccurred = true;
          ShowMessage({ type: "error", message: "Unit Name '" + this.a.Unitname.value + "' already exists." });
          this.utilityServices.stopLoader();
        }
        else {
          this.IsErrorOccurred = false;
          const mo: UnitMaster = new UnitMaster();
          mo.unitID = 0;
          mo.unitname = this.a.Unitname.value;
          mo.sortOrder = this.a.SortOrder.value;
          mo.active = this.a.Active.value;


          this.changeId = 0;
          this.masterService.SaveUnitData(mo).subscribe((res: any) => {
            if (res.isSuccess) {
              this.changeId = res.data;
              this.addUnitForm.reset();
              this.addUnitForm.controls['Active'].setValue(1);
              this.GetAllUnits();
              this.disableEdit();
              $("#default-example-modal-lg-center").modal('hide');
              this.utilityServices.stopLoader();
              ShowMessage({ type: "success", message: res.message });
            }
            else {
              this.utilityServices.stopLoader();
              ShowMessage({
                type: "error", message: res.message
              });
            }
          }, (err) => {
            this.utilityServices.stopLoader();
            ShowMessage({ type: "error", message: "Something went wrong..." });
          });
          this.utilityServices.stopLoader();
        }
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
    //const mo: UnitMaster = new UnitMaster();
    //mo.unitID = 0;
    //mo.unitname = this.a.Unitname.value;
    //mo.sortOrder = this.a.SortOrder.value;
    //mo.active = this.a.Active.value;


    //this.changeId = 0;
    //this.masterService.SaveUnitData(mo).subscribe((res: any) => {
    //  if (res.isSuccess) {
    //    this.changeId = res.data;
    //    this.addUnitForm.reset();
    //    this.addUnitForm.controls['Active'].setValue(1);
    //    this.GetAllUnits();
    //    this.disableEdit();
    //    $("#default-example-modal-lg-center").modal('hide');
    //    this.utilityServices.stopLoader();
    //    ShowMessage({ type: "success", message: res.message });
    //  }
    //  else {
    //    this.utilityServices.stopLoader();
    //    ShowMessage({
    //      type: "error", message: res.message
    //    });
    //  }
    //}, (err) => {
    //  this.utilityServices.stopLoader();
    //  ShowMessage({ type: "error", message: "Something went wrong..." });
    //});

  }
  onlyNumbers(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 47 && k < 59));
  }
  ValidateInput(Id?: string, FieldName?: string, Value?: string, ControlName?: string, fn?: number) {
    this.commonService.ValidateInput("UnitMaster", "UnitID", Id, FieldName, Value).subscribe((res: any) => {      
      if (res.isSuccess) {
        if (res.data == true) {
          this.IsErrorOccurred = true;
          ShowMessage({ type: "error", message: "Unit Name '" + Value + "' already exists." });

          //if (fn == 1 && ControlName == "Unitname") {
          //  this.unitForm.patchValue({
          //    Unitname: ''
          //  });
          //}
          //else if (fn == 2 && ControlName == "Unitname") {
          //  this.addUnitForm.patchValue({
          //    Unitname: ''
          //  });
          //}
        }
        else {
          this.IsErrorOccurred = false;
        }
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
  }
}
