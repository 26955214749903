import { Component } from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { UtilityServices } from './_helpers';
import { BnNgIdleService } from 'bn-ng-idle';
import { AuthenticationService } from './_services/authentication.service';
import { parse } from 'querystring';
import { first } from 'rxjs/operators';

declare function ShowMessage(config): any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'SRX';
  sessionTimeOut: number = 20;
  sessionTimeOutString: string;

  constructor(private router: Router,
    private utilityServices: UtilityServices,
    private bnIdle: BnNgIdleService,
    private authenticationService: AuthenticationService
  ) {
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.utilityServices.startLoader();
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.utilityServices.stopLoader();
          break;
        }
        default: {
          break;
        }
      }
    });
    this.GetSessionTimeOut();
    this.bnIdle.startWatching(this.sessionTimeOut).subscribe((res) => {
      if (res) {
       
        if (JSON.parse(localStorage.getItem('currentUser')) != null) {
          var uname = this.authenticationService.currentUserValue.userName;
          this.authenticationService.LogoutHistory(uname).pipe(first())
            .subscribe(
              data => {
                this.authenticationService.logout();
                this.router.navigate(['/']);
                alert("Your session has expired. Please Login again.");
              },
              error => {
                ShowMessage({ type: "error", message: error });
              });
        }
      }
    });
  }
  GetSessionTimeOut() {
    this.sessionTimeOutString = JSON.parse(localStorage.getItem('SessionTimeOut'));
    if (this.sessionTimeOutString != null) {
      this.sessionTimeOut = Number(this.sessionTimeOutString);
    }
    this.sessionTimeOut = this.sessionTimeOut * 60;
  }
}
