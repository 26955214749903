import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_helpers';
import { Role } from './_models';

import { StaticLayoutComponent } from './static-layout/static-layout.component'
import { MainLayoutComponent } from './main-layout/main-layout.component'

const routes: Routes = [
  {
    path: 'home',
    component: MainLayoutComponent,
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard],
    //data: { roles: [Role.Admin] }
  },
  {
    path: 'accounts',
    component: MainLayoutComponent,
    loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'inventory',
    component: MainLayoutComponent,
    loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'reports',
    component: MainLayoutComponent,
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    component: MainLayoutComponent,
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'masters',
    component: MainLayoutComponent,
    loadChildren: () => import('./masters/masters.module').then(m => m.MastersModule),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    component: StaticLayoutComponent,
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
