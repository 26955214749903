export class permissionMaster {
  pId: number;
  permissionName?: string;
  parentId?: number;
  menuTitle: string;
  menuIcon?: string;
  componentName: string;
  areaName: string;
  controllerName?: string;
  methodName?: string;
  menuType?: number;
  sortingOrder?: number;
  pageURL?: string;
  isActive?: boolean;
 // category: number;
 // permissionTypeID: number;
}
