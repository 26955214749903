import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User, LoginReasonModel } from '../_models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private beforusedclientidsubject: BehaviorSubject<string>;
  public beforusedclientid: Observable<string>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.beforusedclientidsubject = new BehaviorSubject<string>(JSON.parse(localStorage.getItem('beforeUsedClientId')));
    this.beforusedclientid = this.beforusedclientidsubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  public get beforeUsedClientId(): string {
    return this.beforusedclientidsubject.value;
  }
  public get userAccessToken(): string {
    return this.currentUserSubject.value.access_token;
  }

  login(ClientId: string, Username: string, Password: string) {

    var formData: any = new FormData();
    //var ConString = "";
    formData.append("ClientId", ClientId);
    formData.append("Username", Username);
    formData.append("Password", Password);
    //formData.append("ConString", ConString);

    return this.http.post<any>(`${environment.apiUrl}/user-auth/login`, formData)
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user.isSuccess) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          
          localStorage.setItem('currentUser', JSON.stringify(user.data));
          localStorage.removeItem('beforeUsedClientId');
          localStorage.setItem('beforeUsedClientId', JSON.stringify(user.data.clientId));
          localStorage.removeItem('SessionTimeOut');
          localStorage.setItem('SessionTimeOut', JSON.stringify(user.data.sessionTimeOut));
          localStorage.removeItem('isShowReason');
          localStorage.setItem('isShowReason', JSON.stringify(user.data.isShowReason));
          this.currentUserSubject.next(user.data);
        }
        return user;
      }));
      //.pipe(map(data => {
      //  return data;
      //}));
  }
  loginNew(ClientId: string, Username: string, Password: string) {
    var formData: any = new FormData();
    var ConString = "";
    formData.append("ClientId", ClientId);
    formData.append("Username", Username);
    formData.append("Password", Password);
    formData.append("ConString", ConString);

    return this.http.post<any>(`${environment.apiUrl}/user-auth/loginNew`, formData).pipe(map(response => {return response;}));
  }
  register(FirstName: string, LastName: string, Address1: string, EmailID: string, PhoneNo: string, CompanyName: string, Address2: string, CityName: string, StateName: string, ZipCode: string, FindUsFromOther: string, FindUsFrom: number, CountryName: string, Notes: string) {
    var formData: any = new FormData();
    formData.append("FirstName", FirstName);
    formData.append("LastName", LastName);
    formData.append("Address1", Address1);
    formData.append("EmailID", EmailID);
    formData.append("PhoneNo", PhoneNo);
    formData.append("CompanyName", CompanyName);
    formData.append("Address2", Address2);
    formData.append("CityName", CityName);
    formData.append("StateName", StateName);
    formData.append("ZipCode", ZipCode);
    formData.append("FindUsFromOther", FindUsFromOther);
    formData.append("FindUsFrom", FindUsFrom);
    formData.append("CountryName", CountryName);
    formData.append("Notes", Notes);
    return this.http.post<any>(`${environment.apiUrl}/user-auth/registration`, formData)
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user.isSuccess) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user.data));
          this.currentUserSubject.next(user.data);
        }

        return user;
      }));
  }
  logout() {
    
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
  LogoutHistory(userName: string) {
    
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const options = {
      headers: headers
    };
    var formData: any = new FormData();

    //formData.append("UserType", curuser.UserType);
    formData.append("UserName", userName);
    //formData.append("ClinicalRoleId", clinicalRoleId);
    //formData.append("UserSRXId", userSRXID);

    return this.http.post<any>(`${environment.apiUrl}/user-auth/logout-log-transaction`, formData).
      pipe(map(response => response));
  }
  forgotPassword(ClientId: string, EmailId: string) {
    var model: any = new FormData();
    model.append("ClientId", ClientId);
    model.append("EmailId", EmailId);
    return this.http.post<any>(`${environment.apiUrl}/user-auth/forgot-password`, model)
      .pipe(map(res => {
        return res;
      }));
  }
  ValidateResetPassword(eForgotId: string) {
    const parameters = new HttpParams()
      .set("eForgotId", eForgotId);

    const options = {
      params: parameters
    };
    return this.http.get<any>(`${environment.apiUrl}/user-auth/validate-reset-password`, options).pipe(map(response => response));
  }
  RestePassword(ForgotId: string, Password: string) {
    var formData: any = new FormData();
    formData.append("eForgotId", ForgotId);
    formData.append("NewPassword", Password);
    return this.http.post<any>(`${environment.apiUrl}/user-auth/reset-password`, formData)
      .pipe(map(data => {
        return data;
      }));
  }

  //SaveLoginReason(model: LoginReasonModel) {
  //  const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

  //  const options = {
  //    headers: headers
  //  };
  //  
  //  return this.http.post<any>(`${environment.apiUrl}/user-auth/save-login-reason`, model, options).pipe(map(response => response));
  //}

  SaveLoginReason(model: LoginReasonModel) {
    
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);

    const options = {
      headers: headers
    };
    var formData: any = new FormData();
    formData.append("Reason", model.Reason);
    formData.append("ReasonText", model.ReasonText);

    return this.http.post<any>(`${environment.apiUrl}/user-auth/save-login-reason`, formData).
      pipe(map(response => response));
  }
  GetCurrentParent(url: string) {
    const headers = new HttpHeaders().set('Authorization', this.userAccessToken);
    const parameters = new HttpParams().set('url', url.toString());
    ;
    const options = {
      headers: headers,
      params: parameters
    };
    return this.http.get(`${environment.apiUrl}/common/get-current-parent`, options).pipe(map(response => response));
  }
}
