import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Label } from '../../_models';
import { CommonService } from '../../_services/common.service';
import { MastersService } from '../../_services/masters.service';
import { UtilityServices } from '../../_helpers';
import { WhiteSpaceValidator } from '../../_helpers/_validations/must-match';

declare function ShowMessage(config): any;
declare function InitBasicDataTable(selector, title): any;
declare var $: any;
declare function AddRemoveClass(selector): any;

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.css']
})
export class LabelComponent implements OnInit {
  private labelsList: Label[];
  private label: Label;
  IsEdit: boolean = false;
  IsErrorOccurred = false;
  changeId: number;
  rowClicked: any;
  labelForm: FormGroup;
  addLabelForm: FormGroup;
  showDiv: boolean = false;
  constructor(private formBuilder: FormBuilder, private masterService: MastersService, private commonService: CommonService, private utilityServices: UtilityServices) {
    this.GetAllLabels();
  }

  ngOnInit() {
    this.labelForm = this.formBuilder.group({
      ID: [null],
      LabelID: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      LabelXml: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      Active: [null, Validators.required]
    });
    this.disableEdit();
    this.addLabelForm = this.formBuilder.group({
      LabelID: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      LabelXml: [null, [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      Active: [true, Validators.required]
    });
  }
  InitDataTable() {
    InitBasicDataTable("#SRX_LabelMaster", "SRX_LabelMaster");
  }
  AddRemoveClass() {

    AddRemoveClass("#addnewtab");
  }
  get f() {
    return this.labelForm.controls;
  }
  get a() {
    return this.addLabelForm.controls;
  }
  ResetAddForm() {
    this.addLabelForm.reset();
    this.addLabelForm.controls['Active'].patchValue(true);
  }
  GetAllLabels() {

    this.masterService.GetAllLabels().subscribe((res: any) => {
      $('#SRX_LabelMaster').dataTable().fnClearTable();
      $('#SRX_LabelMaster').dataTable().fnDestroy();
      this.labelsList = [];
      this.labelsList = res.data;
      if (this.labelsList.length > 0) {
        if (this.changeId >= 1 && this.labelsList.find(o => o.id == this.changeId) != null) {
          this.label = this.labelsList.find(o => o.id == this.changeId);
          this.changeTableRowColor(this.labelsList.findIndex(o => o.id == this.changeId));
          //$("#SRX_LabelMaster").DataTable().row(this.labelsList.findIndex(o => o.id == this.changeId)).scrollTo();
        }
        else {
          this.label = this.labelsList[0];
          this.changeTableRowColor(0);
        }
        this.showDiv = true;
        this.changeId = this.label.id;
      }
      else {
        this.showDiv = false;
      }
    });
  }
  changeTableRowColor(index: any) {
    this.rowClicked = index;
  }
  GetDetails(printers) {
    this.label = printers;
    this.changeId = this.label.id;
    this.disableEdit();
  }
  enableEdit() {
    this.labelForm.enable();
    this.IsEdit = true;
    if (this.label != null) {
      this.changeId = this.label.id;
    }
  }
  disableEdit() {
    this.labelForm.disable();
    this.IsEdit = false;
    if (this.changeId != null && this.labelsList != null) {
      this.GetAllLabels();
    }
  }
  edit() {
    this.utilityServices.startLoader();
    //this.ValidateInput(this.f.ID.value, 'LabelID', this.f.LabelID.value, 'LabelID', 1);
    //if (this.IsErrorOccurred) {
    //  this.utilityServices.stopLoader();
    //  return;
    //}
    if (this.labelForm.invalid) {
      this.labelForm.markAllAsTouched();
      this.utilityServices.stopLoader();
      //ShowMessage({ type: "error", message: "Please fill form properly" });
      return;
    }
    this.commonService.ValidateInput("Label", "ID", this.f.ID.value, 'LabelID', this.f.LabelID.value).subscribe((res: any) => {
      if (res.isSuccess) {
        if (res.data == true) {
          this.IsErrorOccurred = true;
          this.utilityServices.stopLoader();
          ShowMessage({ type: "error", message: "Label ID '" + this.f.LabelID.value + "' already exists." });

        }
        else {
          this.IsErrorOccurred = false;
          const mo: Label = new Label();
          mo.id = this.f.ID.value;
          mo.labelID = this.f.LabelID.value;
          mo.labelXml = this.f.LabelXml.value;
          mo.active = this.f.Active.value;

          this.changeId = 0;
          this.masterService.SaveLabelData(mo).subscribe((res: any) => {
            if (res.isSuccess) {
              this.changeId = res.data;
              this.IsEdit = false;
              this.addLabelForm.controls['Active'].setValue(1);
              this.GetAllLabels();
              this.disableEdit();
              this.utilityServices.stopLoader();
              ShowMessage({ type: "success", message: res.message });
            }
            else {
              this.utilityServices.stopLoader();
              ShowMessage({
                type: "error", message: res.message
              });
            }
          }, (err) => {
            this.utilityServices.stopLoader();
            ShowMessage({ type: "error", message: "Something went wrong..." });
          });
          this.utilityServices.stopLoader();
        }
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });

    //const mo: Label = new Label();
    //mo.id = this.f.ID.value;
    //mo.labelID = this.f.LabelID.value;
    //mo.labelXml = this.f.LabelXml.value;
    //mo.active = this.f.Active.value;

    //this.changeId = 0;
    //this.masterService.SaveLabelData(mo).subscribe((res: any) => {
    //  if (res.isSuccess) {
    //    this.changeId = res.data;
    //    this.IsEdit = false;
    //    this.addLabelForm.controls['Active'].setValue(1);
    //    this.GetAllLabels();
    //    this.disableEdit();
    //    this.utilityServices.stopLoader();
    //    ShowMessage({ type: "success", message: res.message });
    //  }
    //  else {
    //    this.utilityServices.stopLoader();
    //    ShowMessage({
    //      type: "error", message: res.message
    //    });
    //  }
    //}, (err) => {
    //  this.utilityServices.stopLoader();
    //  ShowMessage({ type: "error", message: "Something went wrong..." });
    //});

  }
  add() {
    this.utilityServices.startLoader();
    //this.ValidateInput('0', 'LabelID', this.a.LabelID.value, 'LabelID', 2);
    //if (this.IsErrorOccurred) {
    //  this.utilityServices.stopLoader();
    //  return;
    //}
    if (this.addLabelForm.invalid) {
      this.addLabelForm.markAllAsTouched();
      this.utilityServices.stopLoader();
      //ShowMessage({ type: "error", message: "Please fill form properly" });
      return;
    }
    this.commonService.ValidateInput("Label", "ID", "0", 'LabelID', this.a.LabelID.value).subscribe((res: any) => {
      if (res.isSuccess) {
        if (res.data == true) {
          this.IsErrorOccurred = true;
          this.utilityServices.stopLoader();
          ShowMessage({ type: "error", message: "Label ID '" + this.a.LabelID.value + "' already exists." });

        }
        else {
          this.IsErrorOccurred = false;
          const mo: Label = new Label();
          mo.id = 0;
          mo.labelID = this.a.LabelID.value;
          mo.labelXml = this.a.LabelXml.value;
          mo.active = this.a.Active.value;


          this.changeId = 0;
          this.masterService.SaveLabelData(mo).subscribe((res: any) => {
            if (res.isSuccess) {
              this.changeId = res.data;

              this.addLabelForm.controls['Active'].setValue(1);
              this.GetAllLabels();
              this.disableEdit();
              this.addLabelForm.reset();
              this.utilityServices.stopLoader();
              ShowMessage({ type: "success", message: res.message });
            }
            else {
              this.utilityServices.stopLoader();
              ShowMessage({
                type: "error", message: res.message
              });
            }
            $("#default-example-modal-lg-center").modal('hide');
          }, (err) => {
            this.utilityServices.stopLoader();
            ShowMessage({ type: "error", message: "Something went wrong..." });
          });
          this.utilityServices.stopLoader();
        }
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });

    //const mo: Label = new Label();
    //mo.id = 0;
    //mo.labelID = this.a.LabelID.value;
    //mo.labelXml = this.a.LabelXml.value;
    //mo.active = this.a.Active.value;


    //this.changeId = 0;
    //this.masterService.SaveLabelData(mo).subscribe((res: any) => {
    //  if (res.isSuccess) {
    //    this.changeId = res.data;

    //    this.addLabelForm.controls['Active'].setValue(1);
    //    this.GetAllLabels();
    //    this.disableEdit();
    //    this.addLabelForm.reset();
    //    this.utilityServices.stopLoader();
    //    ShowMessage({ type: "success", message: res.message });
    //  }
    //  else {
    //    this.utilityServices.stopLoader();
    //    ShowMessage({
    //      type: "error", message: res.message
    //    });
    //  }
    //  $("#default-example-modal-lg-center").modal('hide');
    //}, (err) => {
    //  this.utilityServices.stopLoader();
    //  ShowMessage({ type: "error", message: "Something went wrong..." });
    //});
    //this.utilityServices.stopLoader();

  }
  Remove(ID: number) {
    this.utilityServices.startLoader();
    this.masterService.RemoveLabel(ID.toString()).subscribe((res: any) => {
      if (res.isSuccess) {
        $("#SRX_LabelMaster").DataTable().row(this.labelsList.findIndex(o => o.id == ID)).remove();
        this.GetAllLabels();
        this.utilityServices.stopLoader();
        ShowMessage({ type: "success", message: res.message });
      }
      else {
        this.utilityServices.stopLoader();
        ShowMessage({
          type: "error", message: res.message
        });
      }
    }, (err) => {
      this.utilityServices.stopLoader();
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
  }
  ConfirmDelete(ID: number) {
    var x = confirm("Are you sure want to delete this record?");
    if (x)
      this.Remove(ID);
    else
      return false;
  }
  ValidateInput(Id?: string, FieldName?: string, Value?: string, ControlName?: string, fn?: number) {
    this.commonService.ValidateInput("Label", "ID", Id, FieldName, Value).subscribe((res: any) => {
      if (res.isSuccess) {
        if (res.data == true) {
          this.IsErrorOccurred = true;
          ShowMessage({ type: "error", message: "Label ID '" + Value + "' already exists." });

          //if (fn == 1 && ControlName == "LabelID") {
          //  this.labelForm.patchValue({
          //    LabelID: ''
          //  });
          //}
          //else if (fn == 2 && ControlName == "LabelID") {
          //  this.addLabelForm.patchValue({
          //    LabelID: ''
          //  });
          //}
        }
        else {
          this.IsErrorOccurred = false;
        }
      }
    }, (err) => {
      ShowMessage({ type: "error", message: "Something went wrong..." });
    });
  }
}
