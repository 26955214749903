export class menu {
  menuId: number;
  title?: string;
  path?: string;
  parentId?: number;
  displayOrder?: string;
  Style: string;
 // category: number;
 // permissionTypeID: number;
  categories: menu[]
}
