import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../_services';
import { CommonService } from '../_services/common.service';
import { permissionMaster } from '../_models';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  private permissions: permissionMaster[]
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private commonService: CommonService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
   
    const currentUser = this.authenticationService.currentUserValue;
    //this.GetPermissions();
    const stateList = currentUser.lstPermission.map(function (a) { return a.pageURL; });
    if (currentUser) {
      // check if route is restricted by role
      if (route.data.roles && route.data.roles.indexOf(currentUser.UserType) === -1) {
        // role not authorised so redirect to home page 
        this.router.navigate(['/']);
        return false;
      }
      if (!stateList.includes(state.url.toString())) {
        if (state.url.toString().includes("home") == true) {
          return true;
        }
        else {
          this.router.navigate(['/']);
          return false;
        }
      }
      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
  GetPermissions() {
    this.commonService.GetPermissions().subscribe((res: any) => {
      this.permissions =  res.data;
    });
  }
}
