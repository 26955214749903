export class PermissionList {
  pId: number;
  name?: string;
  parentId?: number;
  menuType?: number;
  roleId?: number;
  userId?: number;
  isSelected?: boolean;
}
export class SavePermission {
  type?: number;
  selectedId?: number;
 // category: number;
  permissionTypeId: number;
  assignedList?: PermissionList[]; 
  deniedList?: PermissionList[];
}
