import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { BlockUIModule } from 'ng-block-ui';
import { RecaptchaModule } from 'ng-recaptcha';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { AppComponent } from './app.component';
import { SidebarMenuComponent } from './sidebar-menu/sidebar-menu.component';
import { StaticLayoutComponent } from './static-layout/static-layout.component'
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { SidebarMenuDirective } from './sidebar-menu/sidebar-menu.directive';
import { MastersModule } from './masters/masters.module';
import { NoopAnimationsModule, BrowserAnimationsModule } from '@angular/platform-browser/animations'
//import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DateAdapter, MatDatepickerModule, MatInputModule, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { AppDatePickerAdapter, APP_DATE_FORMATS } from './_services/_common/datepicker-adapter';
//import { MomentDateAdapter } from '@angular/material-moment-adapter';
//import { Moment } from 'moment';
//import * as moment from 'moment';
import { BnNgIdleService } from 'bn-ng-idle';
//import 'hammerjs';
import { TelerikReportingModule } from '@progress/telerik-angular-report-viewer';

@NgModule({
  declarations: [
    AppComponent,
    SidebarMenuComponent,
    StaticLayoutComponent,
    MainLayoutComponent,
    SidebarMenuDirective
     
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BlockUIModule.forRoot(),
    RouterModule.forRoot([]),
    RecaptchaModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
      
    
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MastersModule,
    NoopAnimationsModule,
    BrowserAnimationsModule,
    
    //NgMultiSelectDropDownModule.forRoot()
    TelerikReportingModule,

  ],
  providers: [BnNgIdleService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    //{
    //  provide: DateAdapter, useClass: AppDatePickerAdapter
    //},
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
    //{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
